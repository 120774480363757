import * as React from 'react';
import { CmdSimpleSelectFilter } from '@commander-services/gui-components';
import { useIntl } from 'react-intl';
import { useRecoilState, useSetRecoilState } from 'recoil';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useKeyPress, { ESCAPE_KEY } from '../../../hooks/useKeyPress';
import { ICmdTableFilter, IFilterChipsValues, ICmdTableFilterTable } from '../types';
import { filterForRequestAtomFamily, tableFilterAtomFamily } from '../CmdTableState';
import useAnalytics from '../../../hooks/useAnalytics';
import { getTableName } from '../CmdTableService';

interface ISimpleSelectFilterProps {
  id: string;
  filterValues: ICmdTableFilterTable;
  tableName: string;
  search?: boolean;
  title?: string;
}

function SimpleSelectFilter(props: ISimpleSelectFilterProps): JSX.Element {
  const [tableActionsFilters, setTableActionsFilters] = useRecoilState(
    tableFilterAtomFamily({ [props.tableName]: props.id })
  );
  const setFilterForRequest = useSetRecoilState<ICmdTableFilter>(
    filterForRequestAtomFamily(props.tableName)
  );
  const { formatMessage: f } = useIntl();
  const { trackEvent } = useAnalytics();

  const clickOutsideRef: React.RefObject<HTMLDivElement> = React.createRef();

  useOutsideClick(clickOutsideRef, () => {
    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: false,
          isActionChipsOpen: false,
        }
    );
  });

  useKeyPress(ESCAPE_KEY, () => {
    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: false,
          isActionChipsOpen: false,
        }
    );
  });

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => {
    event.preventDefault();
    const { id } = event.currentTarget;
    trackEvent([`table ${getTableName(props.tableName)}`, 'click', `Open SimpleSelect filter ${props.id}`]);

    if (Array.isArray(props.filterValues.values)) {
      const item = props.filterValues.values?.find((i) => String(i.value) === String(value));

      if (item) {
        const filterValues: IFilterChipsValues = {
          ...item,
          title: props.title ? props.title : '',
          type: 'simpleSelect',
        };

        const data = {};

        data[props.id] = item.value;

        setFilterForRequest((prevFilter) => ({
          ...prevFilter,
          ...data,
        }));

        setTableActionsFilters(
          (tableActionFilter) =>
            tableActionFilter && {
              ...tableActionFilter,
              id: props.id,
              values: { ...filterValues },
              isOpen: false,
              isActionChipsOpen: false,
              isFilterActive: true,
            }
        );
      }
    }
  };

  const getOptions = (options) => {
    if (Array.isArray(options)) {
      const newOptions =
        options && props.id === 'customerId'
          ? options.map((i) => {
              const item = {
                label: i.item,
                item: i.label,
                value: i.value,
              };
              return item;
            })
          : options;
      return newOptions;
    }
    return [];
  };

  return (
    <div
      ref={clickOutsideRef}
      style={{ position: 'absolute', left: '-24px', bottom: '-15px', zIndex: 999 }}
      className="table-filter"
    >
      <div className="label open" style={{ textAlign: 'left', minWidth: '220px' }}>
        <div>
          {props.filterValues?.values && (
            <CmdSimpleSelectFilter
              onChangeCallback={handleOnClick}
              options={getOptions(props.filterValues.values)}
              value={
                tableActionsFilters && tableActionsFilters.values
                  ? String(tableActionsFilters.values.value)
                  : ''
              }
              width="285px"
              column={props.title}
              id={props.id}
              name={props.id}
              search={props.search}
              searchPlaceholder={f({ id: 'form.search' })}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SimpleSelectFilter;
