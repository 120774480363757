
import { AxiosResponse } from 'axios';
import { IValidationApiResponse } from '../../Services/FormikValidatorService';
import { getRequest, postRequest, putRequest } from '../../Services/HttpService';
import { IChipInputItem } from '../Forms/Fields/ChipInput';
import {
  IAlarmApiResponse,
  IAlarmDefaultRecipientApiResponse,
  IAlarmTimeFrame,
  IAlarmValidationNotificationMessageApiResponse,
  IRecipient,
  TimeFrameFormDataType,
} from './types';

export const activateAlarm = async (id: string): Promise<any> => {
  const response: AxiosResponse | false = await putRequest(`/v1/alarm/${id}/activate`, {});
  return response;
};

export const deactivateAlarm = async (id: string): Promise<any> => {
  const response: AxiosResponse | false = await putRequest(`/v1/alarm/${id}/deactivate`, {});
  return response;
};

export const activateAlarmMultiple = async (ids: string[]): Promise<any> => {
  const response: AxiosResponse | false = await putRequest(`/v1/alarm/activate`, { alarmId: ids });
  return response;
};

export const deactivateAlarmMultiple = async (ids: string[]): Promise<any> => {
  const response: AxiosResponse | false = await putRequest(`/v1/alarm/deactivate`, { alarmId: ids });
  return response;
};

/** Get alam validation */
export const getValidation = async (): Promise<IValidationApiResponse | false> => {
  const response: IValidationApiResponse | AxiosResponse | false = await getRequest<IValidationApiResponse | false>('/v1/alarm/validation');
  return response as IValidationApiResponse | false;
};

export const getNotificationMessageItems = async (
  rideOwnership: string,
  ridePrivacy: string
): Promise<IAlarmValidationNotificationMessageApiResponse | false> => {
  const response: IAlarmValidationNotificationMessageApiResponse | false = await postRequest('/v1/alarm/form/notification-message-tags', {
    rideOwnership,
    ridePrivacy,
  });
  return response;
};

export const getData = async (id: number): Promise<IAlarmApiResponse | null> => {
  const response: AxiosResponse | false = await getRequest(`/v1/alarm/${id}`, { returnAxiosResponse: true});
  if (response && response.data && response.status === 200) {
    const newAlarm = {
      ...response.data.alarm,
      timeFrames: response.data.alarm.timeFrames.map((group: IAlarmTimeFrame[]) => {
        return group.map((item: IAlarmTimeFrame, index: number) => {
          const newItem = {
            ...item,
            label: index > 0 ? ` + ${item.label} ` : item.label,
          };
          return newItem;
        });
      }),
    };
    const newResponse: IAlarmApiResponse = {
      alarm: newAlarm,
    };
    if (response && response.data.validation) {
      newResponse.validation = response.data.validation;
    }
    return newResponse;
  }
  return null;
};

export const prepareRecipients = (recipients: IRecipient[]): IRecipient[] => {
  const newRecipients = Object.values(recipients).map((i: any) => {
    if (!i.name) {
      if (i.fullname) {
        i.name = i.fullname;
      } else if (i.userId) {
        i.name = String(i.userId);
      } else {
        i.name = '';
      }
    }
    if (!i.telephone) {
      if (i.phone) {
        i.telephone = i.phone;
      } else {
        i.telephone = null;
      }
    }
    i.errorNotified = 0;
    i.id = i.id || null;
    i.userId = i.userId || null;
    i.email = i.email || null;
    i.viaMap = !!(i.viaMap && i.userId);
    i.viaRide = !!(i.viaRide && i.userId);
    i.viaEmail = !!(i.viaEmail && i.email);
    i.viaEmailPeriodicity = i.viaEmailPeriodicity || 'once_per_day';
    // i.viaSms = (i.notifications && i.notifications.includes('sms')) || false;
    // i.viaSmsPeriodicity = i.viaSmsPeriodicity || 'once_per_day';
    i.viaCall = !!(i.viaCall && (i.telephone || i.phone));
    i.viaCallPeriodicity = i.viaCallPeriodicity || 'once_per_day';
    i.viaPush = !!(i.viaPush && (i.telephone || i.phone));
    i.viaPushPeriodicity = i.viaPushPeriodicity || 'once_per_day';
    return i;
  });

  return newRecipients;
};

export const getGroupSelectVehicles = async (
  rideOwnership: unknown,
  ridePrivacy: unknown,
  conditions: unknown,
  dispatchSetState: React.Dispatch<React.SetStateAction<any>>
): Promise<void> => {
  const data = {
    rideOwnership,
    ridePrivacy,
    conditions,
  };
  const response = await postRequest('/v1/alarm/form/vehicleId', data);
  if (response) {
    dispatchSetState(response);
  }
};

/** @deprecated: use getDefaultRecipient */
export const getRecipientValues = async (
  dispatchSetState: React.Dispatch<React.SetStateAction<any>>
): Promise<void> => {
  const response = await getRequest('/v1/alarm/form/default-recipient');
  if (response) {
    dispatchSetState([response]);
  }
};

/** Get default recipient */
export const getDefaultRecipient = async (): Promise<IAlarmDefaultRecipientApiResponse | false> => {
  const response = await getRequest<IAlarmDefaultRecipientApiResponse | false>('/v1/alarm/form/default-recipient');
  return response as IAlarmDefaultRecipientApiResponse | false;
};

export const createChipsForTimeFrames = (values: TimeFrameFormDataType): IChipInputItem[] => {
  const chips: IChipInputItem[] = values.map((groupItem: any) => {
    let chipId = 0;
    let chipLabel = '';
    groupItem.map((item: any, index: number) => {
      chipId = item.recordId;
      chipLabel += ` ${item.label} `;
    });
    const chipItem: IChipInputItem = {
      id: chipId,
      label: chipLabel,
    };
    return chipItem;
  });

  return chips;
};

export const createChipsForConditions = (values): IChipInputItem[] => {
  const chips = values.map((item: IChipInputItem) => {
    return item;
  });

  return chips;
};
