import { atom } from 'recoil';

export interface IEcoDriveState {
  ecoDriveScore: number;
  timestamp: string;
  // distance: number | null;
  distance: string | null; // V SocketConnection/index.tsx sa setuje distanceCumulative ako string obsahujuci 'km'
  duration?: number | null;
  maxValue?: string | null;
  type?: string | null;
  map?: string | null;
  tooltip?: string | null;
  link?: string | null;
  address: string | null;
  code: string;
}

export interface IGraphData {
  [name: string]: IEcoDriveState;
}

export const graphDataAtom = atom<IGraphData>({
  key: 'graphData',
  default: {},
});
