import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { CmdLoader } from '@commander-services/gui-components';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Form from './Form';
import OnlineHelp from '../OnlineHelp';
import { IS_BAD_REQUEST, STATUS_OK } from '../../Services/ApiService';
import LoaderService from '../../Services/LoaderService';
import * as ExportState from '../Export/ExportState';
import { IMessageReportCreated } from '../SocketConnection/types';
import { IDownload } from '../Export/ExportState';
import showMessage from '../Toastr/ToastService';
import { selectedCustomersAtom } from '../../store/recoil/customers';
import ReportR01 from './ReportR01';
import ReportR02 from './ReportR02';
import ReportR03 from './ReportR03';
import ReportR04 from './ReportR04';
import ReportR05 from './ReportR05';
import ReportR08 from './ReportR08';
import ReportR07 from './ReportR07';
import ReportR06 from './ReportR06';
import ReportR09 from './ReportR09';
import ReportZ01 from './ReportZ01';
import { IReportItem } from './types';
import { getDownloads, getReports, handleDownloadRequest } from './ReportService';
import { setLoaderAtom } from '../../store/recoil/AppState';
import useAnalytics from '../../hooks/useAnalytics';

export const REPORT_01 = '1';
export const REPORT_02 = '2';
export const REPORT_03 = '3';
export const REPORT_04 = '4';
export const REPORT_05 = '5';
export const REPORT_06 = '6';
export const REPORT_07 = '7';
export const REPORT_Z01 = '1001';
export const REPORT_08 = '8';
export const REPORT_09 = '9';
export const VEHICLE_ENTITY = 'vehicle';
export const DRIVER_ENTITY = 'driver';

export default function Reports(): JSX.Element {
  const { formatMessage: f } = useIntl();
  const showLoader = useRecoilValue<boolean>(setLoaderAtom);
  const [selectedReportId, setSelectedReportId] = React.useState<string>('');
  const [reportList, setReportList] = React.useState<IReportItem[]>([]);
  const selectedCustomers = useRecoilValue<number[]>(selectedCustomersAtom);
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
  const [isAvailable, setIsAvailable] = React.useState<boolean>(true);
  const setDownloads = useSetRecoilState<IDownload[]>(ExportState.downloads);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [isCheckingAvailabledDownloadsEnabled, setIsCheckingAvailabledDownloadsEnabled] =
    React.useState<boolean>(false);
  const setIsActiveExport = useSetRecoilState<false | string>(ExportState.isActive);
  const [valuesJsons, setValuesJsons] = useRecoilState<string[]>(ExportState.valuesJsons);
  const [exportData, setExportData] = useRecoilState<IMessageReportCreated | null>(
    ExportState.data
  );
  const { trackEvent } = useAnalytics();
  

  const handleReportId = (value: string) => {
    setSelectedReportId(value);
    setHasError(false);
    setIsDisabled(false);
  };

  const getReportsList = async () => {
    const options = await getReports(selectedCustomers);
    if (options) {
      setReportList(options);
    }
  };

  const getAvailableDownloads = React.useCallback(async (): Promise<boolean> => {
    const response = await getDownloads();
    if (response) {
      if (!hasError) {
        setIsAvailable(true);
        setIsDisabled(false);
        setIsCheckingAvailabledDownloadsEnabled(false);
        return true;
      }
      return true;
    }
    return false;
  }, [hasError]);

  React.useEffect(() => {
    if (isCheckingAvailabledDownloadsEnabled) {
      const interval = setInterval(() => {
        if (!hasError) {
          getAvailableDownloads();
        }
      }, 30000);
      return () => clearInterval(interval);
    }
    return () => true;
  }, [getAvailableDownloads, hasError, isCheckingAvailabledDownloadsEnabled]);

  React.useEffect(() => {
    if (selectedCustomers && selectedCustomers.length > 0) {
      getAvailableDownloads();
      setSelectedReportId('');
      getReportsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomers]);

  const handleDownload = async (url: string, values: any) => {
    trackEvent(['reports', 'change', 'Handle download']);
    // if previous dowbloading finished
    let finishedDownloading = false;
    if (exportData) {
      setExportData(null);
      finishedDownloading = true;
    }
    if (!finishedDownloading && valuesJsons.includes(JSON.stringify(values))) {
      showMessage(f({ id: 'toastr.warning' }), f({ id: 'reports.inprogress' }), 'warning');
    } else {
      const newValuesJsons = JSON.parse(JSON.stringify(valuesJsons));
      newValuesJsons.push(JSON.stringify(values));
      setValuesJsons(newValuesJsons);
      LoaderService.showLoader(true);

      const res: any = handleDownloadRequest(url, values);
      if (res && res.data && res.status === IS_BAD_REQUEST) {
        setHasError(true);
      }
      if (res && res.data && res.data.status === STATUS_OK) {
        console.info('Generating export: ', res.data.jobId);
        setIsActiveExport(res.data.jobId);
        setIsCheckingAvailabledDownloadsEnabled(true);
        setDownloads((oldState: IDownload[]) => {
          const job = oldState.find((item: IDownload) => item.jobId === res.data.jobId);
          if (job) {
            const updatedJob: IDownload = {
              ...job,
              isDownloaded: false,
              code: selectedReportId,
              percentage: 0,
              generating_end: dayjs().toISOString(),
            };
            const newState = [
              ...oldState.filter((item: IDownload) => item.jobId !== res.data.jobId),
              updatedJob,
            ];
            return newState;
          }
          const newJob: IDownload = {
            jobId: res.data.jobId,
            isDownloaded: false,
            code: `R0${selectedReportId}`,
            percentage: 0,
            generating_start: dayjs().toISOString(),
            generating_end: null,
            filename: `R0${selectedReportId}`,
          };
          const newState = [...oldState, newJob];
          return newState;
        });
      }
      LoaderService.showLoader(false);
    }
  };

  const handleMainButton = (indexId: string, value: boolean) => {
    switch (indexId) {
      case 'hasError':
        setHasError(value);
        break;
      case 'isDisabled':
        setIsDisabled(value);
        break;
      default:
    }
  };

  const documentTitle = `${f({ id: 'menu.manualReports' })} - ${f({ id: 'menu.reports' })}`;
  useDocumentTitle(documentTitle);

  return (
    <div className="col-12" data-cy="reports">
      {showLoader && <CmdLoader inContent={true} />}
      <div className="row section page-head" data-cy="reports-head">
        <h4 data-cy="reports-title">
          {`${f({ id: 'menu.reports' })} / ${f({ id: 'menu.manualReports' })}`}
          <OnlineHelp topic="reports" />
        </h4>
      </div>
      <div data-cy="reports-form-component">
        {!selectedReportId && reportList.length > 0 && (
          <Form
            reportsList={reportList}
            handleReportId={handleReportId}
            buttonDisabled={!isAvailable || isDisabled}
          />
        )}
        {selectedReportId === REPORT_01 && (
          <ReportR01
            reportsList={reportList}
            selectedReportId={selectedReportId}
            handleReportId={handleReportId}
            handleMainButton={handleMainButton}
            buttonDisabled={!isAvailable || isDisabled}
            handleDownload={handleDownload}
          />
        )}
        {selectedReportId === REPORT_02 && (
          <ReportR02
            reportsList={reportList}
            selectedReportId={selectedReportId}
            handleReportId={handleReportId}
            handleMainButton={handleMainButton}
            buttonDisabled={!isAvailable || isDisabled}
            handleDownload={handleDownload}
          />
        )}
        {selectedReportId === REPORT_03 && (
          <ReportR03
            reportsList={reportList}
            selectedReportId={selectedReportId}
            handleReportId={handleReportId}
            handleMainButton={handleMainButton}
            buttonDisabled={!isAvailable || isDisabled}
            handleDownload={handleDownload}
          />
        )}
        {selectedReportId === REPORT_04 && (
          <ReportR04
            reportsList={reportList}
            selectedReportId={selectedReportId}
            handleReportId={handleReportId}
            handleMainButton={handleMainButton}
            buttonDisabled={!isAvailable || isDisabled}
            handleDownload={handleDownload}
          />
        )}
        {selectedReportId === REPORT_05 && (
          <ReportR05
            reportsList={reportList}
            selectedReportId={selectedReportId}
            handleReportId={handleReportId}
            handleMainButton={handleMainButton}
            buttonDisabled={!isAvailable || isDisabled}
            handleDownload={handleDownload}
          />
        )}
        {selectedReportId === REPORT_06 && (
          <ReportR06
            reportsList={reportList}
            selectedReportId={selectedReportId}
            handleReportId={handleReportId}
            handleMainButton={handleMainButton}
            buttonDisabled={!isAvailable || isDisabled}
            handleDownload={handleDownload}
          />
        )}
        {selectedReportId === REPORT_07 && (
          <ReportR07
            reportsList={reportList}
            selectedReportId={selectedReportId}
            handleReportId={handleReportId}
            handleMainButton={handleMainButton}
            buttonDisabled={!isAvailable || isDisabled}
            handleDownload={handleDownload}
          />
        )}
        {selectedReportId === REPORT_08 && (
          <ReportR08
            reportsList={reportList}
            selectedReportId={selectedReportId}
            handleReportId={handleReportId}
            handleMainButton={handleMainButton}
            buttonDisabled={!isAvailable || isDisabled}
            handleDownload={handleDownload}
          />
        )}
        {selectedReportId === REPORT_09 && (
          <ReportR09
            reportsList={reportList}
            selectedReportId={selectedReportId}
            handleReportId={handleReportId}
            handleMainButton={handleMainButton}
            buttonDisabled={!isAvailable || isDisabled}
            handleDownload={handleDownload}
          />
        )}
        {selectedReportId === REPORT_Z01 && (
          <ReportZ01
            reportsList={reportList}
            selectedReportId={selectedReportId}
            handleReportId={handleReportId}
            handleMainButton={handleMainButton}
            buttonDisabled={!isAvailable || isDisabled}
            handleDownload={handleDownload}
          />
        )}
      </div>
    </div>
  );
}
