import * as React from 'react';
import { CmdMultipleSelectFilter } from '@commander-services/gui-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useIntl } from 'react-intl';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useKeyPress, { ESCAPE_KEY } from '../../../hooks/useKeyPress';
import { ICmdTableFilter, IFilterChipsValues, ICmdTableFilterTable } from '../types';
import { filterForRequestAtomFamily, tableFilterAtomFamily } from '../CmdTableState';
import useAnalytics from '../../../hooks/useAnalytics';
import { getTableName } from '../CmdTableService';

interface IMultiSelectFilterProps {
  id: string;
  filterValues: ICmdTableFilterTable;
  tableName: string;
  title?: string;
}

function MultiSelectFilter(props: IMultiSelectFilterProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [tableActionsFilters, setTableActionsFilters] = useRecoilState(
    tableFilterAtomFamily({ [props.tableName]: props.id })
  );
  const setFilterForRequest = useSetRecoilState<ICmdTableFilter>(
    filterForRequestAtomFamily(props.tableName)
  );
  const { trackEvent } = useAnalytics();

  const clickOutsideRef: React.RefObject<HTMLDivElement> = React.createRef();

  useOutsideClick(clickOutsideRef, () => {
    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: false,
          isActionChipsOpen: false,
        }
    );
  });

  useKeyPress(ESCAPE_KEY, () => {
    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: false,
          isActionChipsOpen: false,
        }
    );
  });

  const handleOnClick = (number: number, value: (string | number)[]) => {
    trackEvent([`table ${getTableName(props.tableName)}`, 'click', `Open MultiSelect Filter ${props.id}`]);
    // Check if 'values' is an array before filtering
    if (Array.isArray(props.filterValues.values)) {
      const items = props.filterValues.values.filter((i) => value.includes(String(i.value)));

      if (items.length > 0) {
        const selectedValues: IFilterChipsValues = {
          item: items.length === 1 ? items[0].item : items.length,
          value,
          title: props.title ? props.title : '',
          type: 'multiSelect',
        };

        const data = {};

        data[props.id] = { ...value };

        setFilterForRequest((prevFilter) => ({
          ...prevFilter,
          ...data,
        }));

        setTableActionsFilters(
          (tableActionFilter) =>
            tableActionFilter && {
              ...tableActionFilter,
              id: props.id,
              isOpen: false,
              isActionChipsOpen: false,
              isFilterActive: true,
              values: { ...selectedValues },
            }
        );
      }
    } else {
      console.error('Expected an array but got a string.');
    }
  };

  const getOptions = (options) => {
    const newOptions = options.map((i) => {
      const item = {
        item: i.item,
        value: String(i.value),
      };
      return item;
    });
    return newOptions;
  };

  return (
    <div
      ref={clickOutsideRef}
      style={{ position: 'absolute', left: '-24px', bottom: '-15px' }}
      className="table-filter"
    >
      <div className="label open" style={{ textAlign: 'left' }}>
        <div>
          {props.filterValues && props.filterValues.values && (
            <CmdMultipleSelectFilter
              onChangeCallback={handleOnClick}
              options={getOptions(props.filterValues.values)}
              selectButton={f({ id: 'table.apply' })}
              resetButton="Reset"
              searchPlaceholder={f({ id: 'form.search' })}
              chosenText={f({ id: 'form.chosen' })}
              value={
                tableActionsFilters && tableActionsFilters.values
                  ? (tableActionsFilters.values.value as string[])
                  : []
              }
              width="290px"
              id={props.id}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MultiSelectFilter;
