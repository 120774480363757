import { sortBy } from 'lodash';
import { AxiosResponse } from 'axios';
import config from '../../config';
import {
  IVehicle,
  IVehicleData,
  IVehicleGroup,
  IVehiclePosition,
  IVehicles,
  IVehiclesData,
  IVehicleStatus,
  IVehicleStatuses,
  TVehicleStatus,
  VehicleOnlinePanelApiResponse,
} from '../components/Vehicles/types';
import SearchService from './SearchService';
import {
  IVehicleOnlinePanels,
  IVehicleOnlinePanelState,
} from '../components/OnlinePanel/OnlinePanelState';
import { PRIVATE_STATUS } from '../components/Navigator/constants';
import { getRequest, IHttpServiceOptions } from './HttpService';

/**
 * Add vehicle to selected vehicles
 * @param {number[]} state
 * @param {number} vehicleId
 * @returns {number[]}
 */
export const addSelectedVehicle = (state: number[], vehicleId: number): number[] => {
  const newState: number[] = [...state, vehicleId];
  return newState;
};

/**
 * Remove vehicle from selected vehicles
 * @param {number[]} state
 * @param {number} vehicleId
 * @returns {number[]}
 */
export const removeSelectedVehicle = (state: number[], vehicleId: number): number[] => {
  const newState: number[] = state.filter((item) => item !== vehicleId);
  return newState;
};

/**
 * Toggle selected vehicle
 * @param {number[]} state
 * @param {number} vehicleId
 * @returns {number[]}
 */
export const toggleSelectVehicle = (state: number[], vehicleId: number): number[] => {
  const newState: number[] =
    state.indexOf(vehicleId) === -1
      ? addSelectedVehicle(state, vehicleId)
      : removeSelectedVehicle(state, vehicleId);

  return newState;
};

/**
 * Unselect all vehicles by group
 * @param {number[]} selectedVehicles
 * @param {IVehicles} vehicles
 * @param {number} groupId
 * @returns {number[]}
 */
export const unselectAllVehiclesByGroupId = (
  selectedVehicles: number[],
  vehicles: IVehicles,
  groupId: number
): number[] => {
  const newSelectedVehicles: number[] = [];
  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (
      vehicle &&
      vehicle.vehicleGroups &&
      !vehicle.vehicleGroups.includes(groupId) &&
      selectedVehicles.includes(vehicle.id)
    ) {
      newSelectedVehicles.push(vehicle.id);
    }
  });

  const newState: number[] = newSelectedVehicles;

  return newState;
};

/**
 * Unselect all vehicles by customer
 * @param {number[]} selectedVehicles
 * @param {IVehicles} vehicles
 * @param {number} customerId
 * @returns {number[]}
 */
export const unselectAllVehiclesByCustomerId = (
  selectedVehicles: number[],
  vehicles: IVehicles,
  customerId: number | number[]
): number[] => {
  const newSelectedVehicles: number[] = [];

  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (vehicle) {
      const isVehicleCustomerIncluded = Array.isArray(customerId)
        ? customerId.includes(vehicle.customerId)
        : vehicle.customerId === customerId;

      if (!isVehicleCustomerIncluded && selectedVehicles.includes(vehicle.id)) {
        newSelectedVehicles.push(vehicle.id);
      }
    }
  });

  return newSelectedVehicles;
};

/**
 * Select all vehicles by customer
 * @param {IVehicles} vehicles
 * @param {number} customerId
 * @returns {number[]}
 */
export const selectAllVehiclesByCustomerId = (
  selectedVehicles: number[],
  vehicles: IVehicles,
  customerId: number
): number[] => {
  const newSelectedVehicles: number[] = [...selectedVehicles];
  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (vehicle && vehicle.customerId === customerId && !newSelectedVehicles.includes(vehicle.id)) {
      newSelectedVehicles.push(vehicle.id);
    }
  });

  const newState = newSelectedVehicles;

  return newState;
};

/**
 * Convert array of vehicles to vehicles object
 * @param {IVehicle[]} vehicles
 * @returns {IVehicles}P
 */
export const vehicleArrayToObject = (vehicles: IVehicle[]): IVehicles => {
  const vehiclesObject: IVehicles = {};
  vehicles.forEach((vehicle: IVehicle) => {
    vehiclesObject[vehicle.id] = vehicle;
  });
  return vehiclesObject;
};

export const vehicleDataArrayToObject = (vehicleData: IVehicleData[]): IVehiclesData => {
  const vehiclesDataObject: IVehiclesData = {};
  vehicleData.forEach((data: IVehicleData) => {
    vehiclesDataObject[data.vehicleId] = data;
  });
  return vehiclesDataObject;
};

export const vehicleOnlinePanelArrayToObject = (
  vehicleOnlinePanel: IVehicleOnlinePanelState[]
): IVehicleOnlinePanels => {
  const vehicleOnlinePanelsDataObject: IVehicleOnlinePanels = {};
  vehicleOnlinePanel.forEach((data: IVehicleOnlinePanelState) => {
    vehicleOnlinePanelsDataObject[data.vehicleId] = data;
  });
  return vehicleOnlinePanelsDataObject;
};

export const getVehiclesCustomerId = (
  vehicles: IVehicles,
  filteredVehicles: number[],
  customerId: number,
  filterString = ''
): IVehicle[] => {
  if (filteredVehicles.length === 0 && filterString !== '') {
    return [];
  }
  if (filteredVehicles.length === 0 && filterString === '') {
    const vehiclesByCustomerId: IVehicle[] = [];
    Object.keys(vehicles).forEach((vehicleId: string) => {
      const vehicle = vehicles[Number(vehicleId)];
      if (vehicle && vehicle.customerId === customerId) {
        vehiclesByCustomerId.push({
          ...vehicle,
        });
      }
    });
    return sortBy(vehiclesByCustomerId, (vehicle: IVehicle) => vehicle.name);
  }
  const vehiclesByCustomerId: IVehicle[] = [];
  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (vehicle && vehicle.customerId === customerId && filteredVehicles.includes(vehicle.id)) {
      vehiclesByCustomerId.push({
        ...vehicle,
      });
    }
  });
  return sortBy(vehiclesByCustomerId, (vehicle: IVehicle) => vehicle.name);
};

/** Returns all vehicles by customer with vehicle data */
export const getVehicleWithDataByCustomerId = (
  vehicles: IVehicles,
  vehiclesData: IVehiclesData,
  filteredVehicles: number[],
  customerId: number,
  filterString = ''
): IVehicle[] => {
  if (filteredVehicles.length === 0 && filterString !== '') {
    return [];
  }
  if (filteredVehicles.length === 0 && filterString === '') {
    const vehiclesByCustomerId: IVehicle[] = [];
    Object.keys(vehicles).forEach((vehicleId: string) => {
      const vehicle = vehicles[Number(vehicleId)];
      if (vehicle && vehicle.customerId === customerId) {
        vehiclesByCustomerId.push({
          ...vehicle,
          status:
            (vehiclesData[Number(vehicleId)] && vehiclesData[Number(vehicleId)].status) ||
            'UNKNOWN',
          position:
            (vehiclesData[Number(vehicleId)] && vehiclesData[Number(vehicleId)].position) || null,
          lastRide:
            (vehiclesData[Number(vehicleId)] && vehiclesData[Number(vehicleId)].lastRide) || null,
        });
      }
    });
    return sortBy(vehiclesByCustomerId, (vehicle: IVehicle) => vehicle.name);
    // return vehiclesByCustomerId;
  }
  const vehiclesByCustomerId: IVehicle[] = [];
  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (vehicle && vehicle.customerId === customerId && filteredVehicles.includes(vehicle.id)) {
      vehiclesByCustomerId.push({
        ...vehicle,
        status:
          (vehiclesData[Number(vehicleId)] && vehiclesData[Number(vehicleId)].status) || 'UNKNOWN',
        position:
          (vehiclesData[Number(vehicleId)] && vehiclesData[Number(vehicleId)].position) || null,
        lastRide:
          (vehiclesData[Number(vehicleId)] && vehiclesData[Number(vehicleId)].lastRide) || null,
      });
    }
  });
  return sortBy(vehiclesByCustomerId, (vehicle: IVehicle) => vehicle.name);
};

/** Returns selected vehicles with vehicle data by selected customers */
export const getSelectedVehiclesWithDataAndCustomer = (
  selectedVehicles: number[],
  selectedCustomers: number[],
  vehicles: IVehicles,
  vehiclesData: IVehiclesData
): IVehicles => {
  const vehiclesWithDataAndCustomer: IVehicles = {};
  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (
      vehicle &&
      selectedVehicles.includes(Number(vehicleId)) &&
      selectedCustomers.includes(vehicle.customerId)
    ) {
      const data = vehiclesData[Number(vehicleId)];
      const newVehicle: IVehicle = {
        ...vehicle,
        status: data ? data.status : 'UNKNOWN',
        position: data ? data.position : null,
        lastRide: data ? data.lastRide : null,
      };
      vehiclesWithDataAndCustomer[Number(vehicleId)] = newVehicle;
    }
  });
  return vehiclesWithDataAndCustomer;
};

/** Returns position for vehicle */
export const getVehiclePositionByVehicleId = (
  vehiclesData: IVehicleData[],
  vehicleId: number
): IVehiclePosition | null => {
  const data = vehiclesData.find(
    (vehicleData: IVehicleData) => vehicleData.vehicleId === vehicleId
  );
  if (!data) {
    return null;
  }
  return data.position;
};

/** Returns status for vehicle */
export const getVehicleStatusByVehicleId = (
  vehicleStatuses: IVehicleStatus[],
  vehicleId: number
): TVehicleStatus | null => {
  const status = vehicleStatuses.find(
    (vehicleStatus: IVehicleStatus) => vehicleStatus.vehicleId === vehicleId
  );
  if (!status) {
    return null;
  }
  return status.status;
};

/** Count all vehicles by customer wihtout statuses, positions, infowindows */
export const countAllVehiclesByCustomer = (
  vehicles: IVehicles,
  filteredVehicles: number[],
  customerId: number
): number => {
  const vehicleKeys = Object.keys(vehicles);
  if (filteredVehicles.length === 0) {
    let length = 0;
    vehicleKeys.forEach((vehicleId: string) => {
      const vehicle = vehicles[Number(vehicleId)];
      if (vehicle && vehicle.customerId === customerId) {
        length += 1;
      }
    });
    return length;
  }
  let length = 0;
  vehicleKeys.forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (vehicle && vehicle.customerId === customerId && filteredVehicles.includes(vehicle.id)) {
      length += 1;
    }
  });
  return length;
};

/** Count all vehicles by group id wihtou statuses, positions, infowindows */
export const countAllVehiclesByGroupId = (
  vehicles: IVehicles,
  filteredVehicles: number[],
  groupId: number
): number => {
  const vehicleKeys = Object.keys(vehicles);
  if (filteredVehicles.length === 0) {
    let length = 0;
    vehicleKeys.forEach((vehicleId: string) => {
      const vehicle = vehicles[Number(vehicleId)];
      if (vehicle && vehicle.vehicleGroups && vehicle.vehicleGroups.includes(groupId)) {
        length += 1;
      }
    });
    return length;
  }
  let length = 0;
  vehicleKeys.forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (
      vehicle &&
      vehicle.vehicleGroups &&
      vehicle.vehicleGroups.includes(groupId) &&
      filteredVehicles.includes(vehicle.id)
    ) {
      length += 1;
    }
  });
  return length;
};

/** Count all vehicles without statuses, positions, infowindows */
export const countAllVehicles = (
  vehicles: IVehicles,
  filteredVehicles: number[],
  selectedCustomers: number[]
): number => {
  if (filteredVehicles.length === 0) {
    let length = 0;
    Object.keys(vehicles).forEach((vehicleId: string) => {
      const vehicle = vehicles[Number(vehicleId)];
      if (vehicle && selectedCustomers.includes(vehicle.customerId)) {
        length += 1;
      }
    });
    return length;
  }
  let length = 0;
  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (
      vehicle &&
      filteredVehicles.includes(vehicle.id) &&
      selectedCustomers.includes(vehicle.customerId)
    ) {
      length += 1;
    }
  });
  return length;
};

/** Count selected vehicles by customer wihtout statuses, positions, infowindows */
export const countAllSelectedVehiclesByCustomer = (
  selectedVehicles: number[],
  vehicles: IVehicles,
  customerId: number
): number => {
  let length = 0;
  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (vehicle && selectedVehicles.includes(vehicle.id) && vehicle.customerId === customerId) {
      length += 1;
    }
  });
  return length;
};

/** Count all selected vehicles by group wihtout statuses, positions, infowindows */
export const countAllSelectedVehiclesByGroupId = (
  selectedVehicles: number[],
  vehicles: IVehicles,
  groupId: number
): number => {
  let count = 0;

  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (
      vehicle &&
      vehicle.vehicleGroups &&
      groupId &&
      selectedVehicles.includes(vehicle.id) &&
      vehicle.vehicleGroups.includes(groupId)
    ) {
      count += 1;
    }
  });
  return count;
};

/** Coutn all selected vehicles without statuses, positions, infowindows */
export const countAllSelectedVehicles = (
  selectedVehicles: number[],
  vehicles: IVehicles
): number => {
  let length = 0;
  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (vehicle && selectedVehicles.includes(vehicle.id)) {
      length += 1;
    }
  });
  return length;
};

/** Count all vehicles by customer and status */
export const countAllVehiclesByStatusAndCustomer = (
  vehicles: IVehicles,
  vehicleStatuses: IVehicleStatuses,
  filteredVehicles: number[],
  status: TVehicleStatus,
  customerId: number
): number => {
  const vehicleKeys = Object.keys(vehicles);
  const vehicleStatusKeys = Object.keys(vehicleStatuses);
  if (filteredVehicles.length === 0) {
    const vehicleIds: number[] = [];
    vehicleKeys.forEach((vehicleId: string) => {
      const vehicle = vehicles[Number(vehicleId)];
      if (vehicle && vehicle.customerId === customerId) {
        vehicleIds.push(vehicle.id);
      }
    });

    let length = 0;
    vehicleStatusKeys.forEach((vehicleId: string) => {
      const vehicleStatus = vehicleStatuses[Number(vehicleId)];
      if (
        vehicleStatus &&
        ((vehicleStatus.status === status && vehicleStatus.drivingMode === 'business') ||
          (vehicleStatus.drivingMode === 'private' && PRIVATE_STATUS === status)) &&
        vehicleIds.includes(vehicleStatus.vehicleId)
      ) {
        length += 1;
      }
    });
    return length;
  }
  const vehicleIds: number[] = [];
  vehicleKeys.forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (vehicle && vehicle.customerId === customerId && filteredVehicles.includes(vehicle.id)) {
      vehicleIds.push(vehicle.id);
    }
  });

  let length = 0;
  vehicleStatusKeys.forEach((vehicleId: string) => {
    const vehicleStatus = vehicleStatuses[Number(vehicleId)];
    if (
      ((vehicleStatus.status === status && vehicleStatus.drivingMode === 'business') ||
        (vehicleStatus.drivingMode === 'private' && PRIVATE_STATUS === status)) &&
      vehicleIds.includes(vehicleStatus.vehicleId)
    ) {
      length += 1;
    }
  });
  return length;
};

/** Count all vehicles by status and group without positions, infowidows */
export const countAllVehiclesByStatusAndGroup = (
  vehicles: IVehicles,
  vehicleStatuses: IVehicleStatuses,
  filteredVehicles: number[],
  status: TVehicleStatus,
  groupId: number
): number => {
  const vehicleKeys: string[] = Object.keys(vehicles);
  const vehicleStatusKeys: string[] = Object.keys(vehicleStatuses);
  if (filteredVehicles.length === 0) {
    const vehicleIds: number[] = [];
    vehicleKeys.forEach((vehicleId: string) => {
      const vehicle = vehicles[Number(vehicleId)];
      if (vehicle && vehicle.vehicleGroups && vehicle.vehicleGroups.includes(groupId)) {
        vehicleIds.push(vehicle.id);
      }
    });

    let length = 0;
    vehicleStatusKeys.forEach((vehicleId: string) => {
      const vehicleStatus = vehicleStatuses[Number(vehicleId)];
      if (
        vehicleStatus &&
        ((vehicleStatus.status === status && vehicleStatus.drivingMode === 'business') ||
          (vehicleStatus.drivingMode === 'private' && PRIVATE_STATUS === status)) &&
        vehicleIds.includes(vehicleStatus.vehicleId)
      ) {
        length += 1;
      }
    });
    return length;
  }
  let length = 0;
  const vehicleIds: number[] = [];
  vehicleKeys.forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (
      vehicle &&
      vehicle.vehicleGroups &&
      vehicle.vehicleGroups.includes(groupId) &&
      filteredVehicles.includes(vehicle.id)
    ) {
      vehicleIds.push(vehicle.id);
    }
  });

  Object.keys(vehicleStatusKeys).forEach((vehicleId: string) => {
    const vehicleStatus = vehicleStatuses[Number(vehicleId)];
    if (
      vehicleStatus &&
      ((vehicleStatus.status === status && vehicleStatus.drivingMode === 'business') ||
        (vehicleStatus.drivingMode === 'private' && PRIVATE_STATUS === status)) &&
      vehicleIds.includes(vehicleStatus.vehicleId)
    ) {
      length += 1;
    }
  });
  return length;
};

/** Count all vehicles by status wihtout position, infowindowns, ... */
export const countAllVehiclesByStatus = (
  vehicles: IVehicles,
  vehicleStatuses: IVehicleStatuses,
  filteredVehicles: number[],
  selectedCustomers: number[],
  status: TVehicleStatus
): number => {
  if (filteredVehicles.length === 0) {
    const vehicleIds: number[] = [];
    Object.keys(vehicles).forEach((vehicleId: string) => {
      const vehicle = vehicles[Number(vehicleId)];
      if (vehicle && selectedCustomers.includes(vehicle.customerId)) {
        vehicleIds.push(vehicle.id);
      }
    });
    let length = 0;
    Object.keys(vehicleStatuses).forEach((vehicleId: string) => {
      const vehicleStatus = vehicleStatuses[Number(vehicleId)];
      if (
        vehicleStatus &&
        ((vehicleStatus.status === status && vehicleStatus.drivingMode === 'business') ||
          (vehicleStatus.drivingMode === 'private' && PRIVATE_STATUS === status)) &&
        vehicleIds.includes(vehicleStatus.vehicleId)
      ) {
        length += 1;
      }
    });
    return length;
  }
  const vehicleIds: number[] = [];
  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (
      vehicle &&
      selectedCustomers.includes(vehicle.customerId) &&
      filteredVehicles.includes(vehicle.id)
    ) {
      vehicleIds.push(vehicle.id);
    }
  });

  let length = 0;
  Object.keys(vehicleStatuses).forEach((vehicleId: string) => {
    const vehicleStatus = vehicleStatuses[Number(vehicleId)];
    if (
      vehicleStatus &&
      ((vehicleStatus.status === status && vehicleStatus.drivingMode === 'business') ||
        (vehicleStatus.drivingMode === 'private' && PRIVATE_STATUS === status)) &&
      vehicleIds.includes(vehicleStatus.vehicleId)
    ) {
      length += 1;
    }
  });
  return length;
};

/** Count all selected vehicles by customer and status withour posiion, infowindows, ... */
export const countAllSelectedVehiclesByStatusAndCustomer = (
  vehicles: IVehicles,
  vehicleStatuses: IVehicleStatuses,
  selectedVehicles: number[],
  status: TVehicleStatus,
  customerId: number
): number => {
  const vehicleIds: number[] = [];
  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (vehicle && selectedVehicles.includes(vehicle.id) && vehicle.customerId === customerId) {
      vehicleIds.push(vehicle.id);
    }
  });

  let length = 0;
  Object.keys(vehicleStatuses).forEach((vehicleId: string) => {
    const vehicleStatus = vehicleStatuses[Number(vehicleId)];
    if (
      vehicleStatus &&
      ((vehicleStatus.status === status && vehicleStatus.drivingMode === 'business') ||
        (vehicleStatus.drivingMode === 'private' && PRIVATE_STATUS === status)) &&
      vehicleIds.includes(vehicleStatus.vehicleId)
    ) {
      length += 1;
    }
  });
  return length;
};

/** Count all selected vehicles by group and status without position, infowindow, .... */
export const countAllSelectedVehiclesByGroupIdAndStatus = (
  vehicles: IVehicles,
  vehicleStatuses: IVehicleStatuses,
  selectedVehicles: number[],
  status: TVehicleStatus,
  groupId: number
): number => {
  const vehicleKeys = Object.keys(vehicles);
  const vehicleStatusKeys = Object.keys(vehicleStatuses);
  const vehicleIds: number[] = [];
  vehicleKeys.forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (
      vehicle &&
      selectedVehicles.includes(vehicle.id) &&
      vehicle.vehicleGroups &&
      vehicle.vehicleGroups.includes(groupId)
    ) {
      vehicleIds.push(vehicle.id);
    }
  });

  let length = 0;
  vehicleStatusKeys.forEach((vehicleId: string) => {
    const vehicleStatus = vehicleStatuses[Number(vehicleId)];
    if (
      vehicleStatus &&
      ((vehicleStatus.status === status && vehicleStatus.drivingMode === 'business') ||
        (vehicleStatus.drivingMode === 'private' && PRIVATE_STATUS === status)) &&
      vehicleIds.includes(vehicleStatus.vehicleId)
    ) {
      length += 1;
    }
  });
  return length;
};

/** Count all selected vehi */
export const countAllSelectedVehiclesByStatus = (
  vehicles: IVehicles,
  vehicleStatuses: IVehicleStatuses,
  selectedVehicles: number[],
  status: TVehicleStatus
): number => {
  let length = 0;
  Object.keys(vehicleStatuses).forEach((vehicleId: string) => {
    const vehicleStatus = vehicleStatuses[Number(vehicleId)];
    if (
      vehicleStatus &&
      selectedVehicles.includes(vehicleStatus.vehicleId) &&
      ((vehicleStatus.status === status && vehicleStatus.drivingMode === 'business') ||
        (vehicleStatus.drivingMode === 'private' && PRIVATE_STATUS === status))
    ) {
      length += 1;
    }
  });
  return length;
};

/** Returns all vehicles by group with vehicle data */
export const getVehiclesWithDataByGroupId = (
  vehicles: IVehicles,
  vehiclesData: IVehiclesData,
  filteredVehicles: number[],
  groupId: number,
  filterString = ''
): IVehicle[] => {
  if (filteredVehicles.length === 0 && filterString === '') {
    const vehiclesList: IVehicle[] = [];
    Object.keys(vehicles).forEach((vehicleId: string) => {
      const vehicle = vehicles[Number(vehicleId)];
      if (vehicle && vehicle.showInNavigatorGroups && vehicle.showInNavigatorGroups[groupId]) {
        const data = vehiclesData[vehicle.id];
        if (data) {
          const vehicleWithData = {
            ...vehicle,
            status: data ? data.status : 'UNKNOWN',
            position: data ? data.position : null,
            // infoWindow: data ? data.infoWindow : null,
            lastRide: data ? data.lastRide : null,
          };
          vehiclesList.push(vehicleWithData);
        }
      }
    });
    return sortBy(vehiclesList, (vehicle: IVehicle) => vehicle.name);
    // return vehiclesList;
  }
  if (filteredVehicles.length === 0 && filterString !== '') {
    return [];
  }
  const vehiclesList: IVehicle[] = [];
  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (
      vehicle &&
      vehicle.vehicleGroups &&
      vehicle.vehicleGroups.includes(groupId) &&
      filteredVehicles.includes(vehicle.id)
    ) {
      const data = vehiclesData[vehicle.id];
      if (data) {
        const vehicleWithData = {
          ...vehicle,
          status: data ? data.status : 'UNKNOWN',
          position: data ? data.position : null,
          // infoWindow: data ? data.infoWindow : null,
          lastRide: data ? data.lastRide : null,
        };
        vehiclesList.push(vehicleWithData);
      }
    }
  });
  return sortBy(vehiclesList, (vehicle: IVehicle) => vehicle.name);
  // return vehiclesList;
};

/** Check if group has filtered vehicles */
export const hasFilteredVehiclesByGroupId = (
  vehicles: IVehicles,
  filteredVehicles: number[],
  groupId: number,
  filterString: string
): boolean => {
  if (filterString === '') {
    return true;
  }

  let numberOfVehicles = 0;
  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (
      vehicle &&
      vehicle.vehicleGroups &&
      vehicle.vehicleGroups.includes(groupId) &&
      filteredVehicles.includes(vehicle.id)
    ) {
      numberOfVehicles += 1;
    }
  });

  if (numberOfVehicles > 0 && filterString !== '') {
    return true;
  }
  return false;
};

/** Get vehicle groups by selected customers */
export const getVehicleGroupsBySelectedCustomers = (
  vehicleGroups: IVehicleGroup[],
  selectedCustomers: number[]
): IVehicleGroup[] => {
  return vehicleGroups.filter((vehicleGroup: IVehicleGroup) =>
    selectedCustomers.includes(vehicleGroup.customerId)
  );
};

/** Get selected vehicles by customer */
export const getSelectedVehiclesByCustomerId = (
  vehicles: IVehicles,
  selectedVehicles: number[],
  customerId: number
): IVehicles => {
  const vehiclesList: IVehicles = {};
  Object.keys(vehicles).forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (vehicle && vehicle.customerId === customerId && selectedVehicles.includes(vehicle.id)) {
      vehiclesList[Number(vehicleId)] = vehicle;
    }
  });
  return vehiclesList;
};

/** Get color by vehicle status */
export const getColorByStatus = (status: TVehicleStatus): string => {
  return config.statusColor[status].fill;
};

export const updateVehicleData = (state: IVehicleData[], data: IVehicleData): IVehicleData[] => {
  const index = state.findIndex(
    (vehicleData: IVehicleData) => vehicleData.vehicleId === data.vehicleId
  );

  if (index === -1) {
    const newState: IVehicleData[] = [...state, data];

    // console.log('NewState index -1:', newState);
    return newState;
  }

  const newState: IVehicleData[] = [
    ...state.slice(0, index),
    {
      ...state[index],
      status: data.status,
      position: data.position ? data.position : state[index].position,
      // activeAlarm: data.alarms,
      lastRide: data.lastRide ? data.lastRide : state[index].lastRide,
    },
    ...state.slice(index + 1),
  ];

  return newState;
};

/**
 * Filter vehicles by name, id, licence number and driver name
 * @param {IVehicles} vehicles
 * @param {string} filterString
 * @returns {number[]}
 */
export const filter = (vehicles: IVehicles, filterString: string): number[] => {
  if (filterString === '') {
    return [];
  }

  const vehicleKeys = Object.keys(vehicles);

  let tempString = String(filterString);

  // tempString = tempString.replace(/[^\w\s]/g, '');
  tempString = tempString.replace(/\s+/g, ' ').trim();

  const regexp = new RegExp(tempString.toLowerCase());

  const filteredVehiclesByName: number[] = [];
  vehicleKeys.forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (
      vehicle &&
      vehicle.name &&
      regexp.test(SearchService.removeAccent(vehicle.name.toLowerCase()))
    ) {
      filteredVehiclesByName.push(vehicle.id);
    }
  });

  const filteredVehiclesByLicenceNumber: number[] = [];
  vehicleKeys.forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (vehicle && vehicle.licenceNumber && regexp.test(vehicle.licenceNumber.toLowerCase())) {
      filteredVehiclesByName.push(vehicle.id);
    }
  });

  const filteredVehiclesByDriverName: number[] = [];
  vehicleKeys.forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (
      vehicle &&
      vehicle.driver.name &&
      regexp.test(SearchService.removeAccent(vehicle.driver.name.toLowerCase()))
    ) {
      filteredVehiclesByName.push(vehicle.id);
    }
  });

  const filteredVehiclesByVehicleId: number[] = [];
  vehicleKeys.forEach((vehicleId: string) => {
    const vehicle = vehicles[Number(vehicleId)];
    if (vehicle && regexp.test(String(vehicle.id))) {
      filteredVehiclesByName.push(vehicle.id);
    }
  });

  const filteredVehicles: number[] = [
    ...filteredVehiclesByName,
    ...filteredVehiclesByLicenceNumber,
    ...filteredVehiclesByDriverName,
    ...filteredVehiclesByVehicleId,
  ];

  return filteredVehicles;
};

export const getOnlinePanelForVehicle = async (
  vehicleId: number | false
): Promise<VehicleOnlinePanelApiResponse | false> => {
  if (!vehicleId) {
    return false;
  }
  const options: IHttpServiceOptions = {
    showErrorToaster: false,
    showToaster: false,
    returnAxiosResponse: false,
    returnAxioErrorResponseData: false,
  };
  const response: VehicleOnlinePanelApiResponse | AxiosResponse<unknown, any> | false =
    await getRequest<VehicleOnlinePanelApiResponse | false>(
      `v1/vehicle/${vehicleId}/online-panel`,
      options
    );

  return response as VehicleOnlinePanelApiResponse | false;
};
