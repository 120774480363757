import { isEqual } from 'lodash';
import ApiService, { RESPONSE_OK } from '../../Services/ApiService';
import { ICustomer } from '../../interfaces/customer';
import { IBranch } from '../../interfaces/branch';
import {
  ISalesChannelListResponseData,
  IVehicleListResponseVehicleData,
  IVehicleGroupListApiResponse,
  IVehicleGroupListApiResponseData,
} from '../../interfaces/apiResponse';
import { IOnlinePanelData } from '../OnlineMapNew/PanelsLayer/types';
import {
  IVehicleOnlinePanelElectric,
  IVehicleOnlinePanelState,
} from '../OnlinePanel/OnlinePanelState';
import { PRIVATE_STATUS } from './constants';
import {
  IShowInNavigatorGroups,
  IVehicle,
  IVehicleData,
  IVehicleDriver,
  IVehicleGroup,
  IVehicleLastRide,
  IVehiclePosition,
  IVehicles,
  IVehicleStatuses,
  TVehicleStatus,
} from '../Vehicles/types';
import Config from '../../../config';
import { showMessage } from '../Toastr/ToastService';
import NavigatorDataSchema from './NavigatorDataSchema.json';
import JSONValidationService from '../../Services/JSONValidationService';
import NavigatorVehicleGroupsSchema from './NavigatorVehicleGroupsSchema.json';
import { getCustomerId } from '../../Services/UserService';
import { getRequest, postRequest } from '../../Services/HttpService';
import { IWidget } from '../OnlinePanel/Widgets/types';
import {
  IDriverApiResponse,
  INavigatorMapDataApiRequestData,
  INavigatorMapDataApiResponse,
} from './types';

export const getVehicleGroups = async (): Promise<IVehicleGroup[] | null> => {
  const response = await getRequest<IVehicleGroupListApiResponse>(
    '/v1/user/vehicle-group/list',
    {}
  );
  if (response as IVehicleGroupListApiResponse) {
    JSONValidationService.validateSchema(
      NavigatorVehicleGroupsSchema,
      response,
      'Navigator vehicle groups Data Schema'
    );
    if (response as IVehicleGroupListApiResponse) {
      const vehicleGroups = (response as IVehicleGroupListApiResponse).vehicleGroups.map(
        (vehicleGroupData: IVehicleGroupListApiResponseData) => {
          const vehicleGroup: IVehicleGroup = {
            id: vehicleGroupData.id,
            name: vehicleGroupData.name,
            customerId: vehicleGroupData.customer_id,
          };
          return vehicleGroup;
        }
      );
      return vehicleGroups;
    }
    return null;
  }
  return null;
};

const randomAngle = (): number => {
  return Math.random() * (360 - 1) + 1;
};

export const getDriver = async (driverId: number): Promise<IDriverApiResponse | null> => {
  const response = await getRequest<IDriverApiResponse>(`/v1/driver/${driverId}`, {
    showErrorToaster: false,
    showToaster: false,
  });

  if (response) {
    return response as IDriverApiResponse;
  }

  return null;
};

export const getVehicles = async (): Promise<{
  vehicles: IVehicle[];
  vehiclesData: IVehicleData[];
  usedWidgets: IWidget[];
  onlineMapTemplate: IOnlinePanelData[];
  onlinePanelData: IVehicleOnlinePanelState[];
} | null> => {
  const response = await getRequest<INavigatorMapDataApiResponse>('/v1/user/navigator-map/data');

  if (response) {
    JSONValidationService.validateSchema(NavigatorDataSchema, response, 'Navigator Data Schema');

    const vehicles: IVehicle[] = [];
    const vehiclePositions: IVehiclePosition[] = [];

    const vehiclesData: IVehicleListResponseVehicleData[] = (
      response as INavigatorMapDataApiResponse
    ).vehicles;
    const data: IVehicleData[] = [];
    const usedWidgets: IWidget[] = (response as INavigatorMapDataApiResponse).widgets;
    const onlineMapTemplate: IOnlinePanelData[] = (response as INavigatorMapDataApiResponse)
      .onlineMapTemplate;
    const onlinePanelData: IVehicleOnlinePanelState[] = [];

    let index: number;
    for (index = 0; index < vehiclesData.length; index += 1) {
      const vehicleDriver: IVehicleDriver = {
        id: vehiclesData[index].driverId ? vehiclesData[index].driverId : null,
        name: vehiclesData[index].driverName ? vehiclesData[index].driverName : null,
      };

      const showInNavigatorGroups: IShowInNavigatorGroups = {};
      vehiclesData[index].vehicleGroups.forEach((groupId: number) => {
        showInNavigatorGroups[groupId] = true;
      });

      const onlinePanel = vehiclesData[index].onlinePanel;
      let lastPublicvehiclePosition: IVehiclePosition | null = null;

      if (onlinePanel) {
        lastPublicvehiclePosition = {
          latitude: vehiclesData[index].latitude as number,
          longitude: vehiclesData[index].longitude as number,
          altitude: onlinePanel.altitude,
          angle: onlinePanel.angle || randomAngle(),
          timestamp: vehiclesData[index].timestamp,
          address: onlinePanel.address,
        };
      }

      const vehicle: IVehicle = {
        showInNavigatorGroups,
        id: vehiclesData[index].vehicleId,
        name: vehiclesData[index].name,
        licenceNumber: vehiclesData[index].licenceNumber,
        vehicleTypeId: vehiclesData[index].vehicleTypeId,
        customerId: vehiclesData[index].customerId,
        vehicleGroups: vehiclesData[index].vehicleGroups,
        driver: vehicleDriver,
        showInNavigator: true,
        drivingMode: vehiclesData[index].drivingMode,
      };

      let vehiclePosition: IVehiclePosition | null = null;
      if (vehiclesData[index].latitude && vehiclesData[index].longitude && onlinePanel) {
        vehiclePosition = {
          latitude: vehiclesData[index].latitude as number,
          longitude: vehiclesData[index].longitude as number,
          angle: onlinePanel.angle || randomAngle(),
          altitude: onlinePanel.altitude,
          timestamp: vehiclesData[index].timestamp,
          address: onlinePanel.address,
          distanceCumulative: 0,
        };
      } else if (vehiclesData[index].latitude && vehiclesData[index].longitude && !onlinePanel) {
        vehiclePosition = {
          latitude: vehiclesData[index].latitude as number,
          longitude: vehiclesData[index].longitude as number,
          angle: randomAngle(),
          altitude: 0,
          timestamp: '',
          address: null,
          distanceCumulative: 0,
        };
      }

      let vehicleLastRide: IVehicleLastRide | null = null;
      if (vehiclesData[index].lastRide) {
        vehicleLastRide = {
          start: {
            timestamp: vehiclesData[index].lastRide?.start.timestamp || '',
            latitude: vehiclesData[index].lastRide?.start.latitude || 0.0,
            longitude: vehiclesData[index].lastRide?.start.longitude || 0.0,
            address: vehiclesData[index].lastRide?.start.address || '',
          },
          end: vehiclesData[index]?.lastRide?.end || null,
        };
      }

      vehicles.push(vehicle);
      if (vehiclePosition) {
        vehiclePositions.push(vehiclePosition);
      }

      if (onlinePanel && vehiclesData[index].latitude && vehiclesData[index].longitude) {
        const onlinePanelDataItem: IVehicleOnlinePanelState = {
          vehicleId: vehiclesData[index].vehicleId,
          altitude: onlinePanel.altitude,
          battery: onlinePanel.battery,
          engineTemperature: onlinePanel.engineTemperature,
          fuelLevelLiters: onlinePanel.fuelLevelLiters,
          fuelLevelPercentage: onlinePanel.fuelLevelPercentage,
          rpm: onlinePanel.rpm,
          satellites: onlinePanel.satellites,
          speed: onlinePanel.speed,
          throttle: onlinePanel.throttle,
          ecoDriveScore: onlinePanel.ecoDriveScore,
          timestamp: vehiclesData[index].timestamp,
          latitude: vehiclesData[index].latitude as number,
          longitude: vehiclesData[index].longitude as number,
          address: onlinePanel.address,
          announcements: onlinePanel.announcements,
          ignition: vehiclesData[index].ignition,
          onCng: onlinePanel.onCng,
          onCruise: onlinePanel.onCruise,
          onLpg: onlinePanel.onLpg,
          remoteLock: onlinePanel.remoteLock,
          webasto: null,
          pto: onlinePanel.pto,
          engineState: onlinePanel.engineState,
          electric: onlinePanel.electric,
          mileage: onlinePanel.mileage,
          code: '',
          seatbelts: onlinePanel.seatbelts,
          coolantLevelLow: onlinePanel.coolantLevelLow,
          oilLevelLow: onlinePanel.oilLevelLow,
          checkEngine: onlinePanel.checkEngine,
          esp: onlinePanel.esp,
          adBlueLevel: onlinePanel.adBlueLevel,
        };
        onlinePanelData.push(onlinePanelDataItem);
      }

      const vehicleData: IVehicleData = {
        vehicleId: vehiclesData[index].vehicleId,
        position: vehiclePosition,
        status: vehiclesData[index].status as TVehicleStatus,
        lastRide: vehicleLastRide,
        templateId: vehiclesData[index].templateId,
        drivingMode: vehiclesData[index].drivingMode,
      };

      data.push(vehicleData);
    }

    return {
      vehicles,
      vehiclesData: data,
      usedWidgets,
      onlineMapTemplate,
      onlinePanelData,
    };
  }

  return null;
};

export const getVehiclesbyIds = async (
  vehicleIds: number[]
): Promise<{
  vehicles: IVehicle[];
  vehiclesData: IVehicleData[];
  usedWidgets: IWidget[];
  onlineMapTemplate: IOnlinePanelData[];
  onlinePanelData: IVehicleOnlinePanelState[];
} | null> => {
  const requestData = {
    vehicleId: vehicleIds,
  };

  const response = await postRequest<
    INavigatorMapDataApiRequestData,
    INavigatorMapDataApiResponse | false
  >('/v1/user/navigator-map/data', requestData, { showToaster: false, showErrorToaster: false });

  if (response) {
    JSONValidationService.validateSchema(NavigatorDataSchema, response, 'Navigator Data Schema');

    const vehicles: IVehicle[] = [];
    const vehiclePositions: IVehiclePosition[] = [];

    const vehiclesData: IVehicleListResponseVehicleData[] = (
      response as INavigatorMapDataApiResponse
    ).vehicles;
    const data: IVehicleData[] = [];
    const usedWidgets: IWidget[] = (response as INavigatorMapDataApiResponse).widgets;
    const onlineMapTemplate: IOnlinePanelData[] = (response as INavigatorMapDataApiResponse)
      .onlineMapTemplate;
    const onlinePanelData: IVehicleOnlinePanelState[] = [];

    let index: number;
    for (index = 0; index < vehiclesData.length; index += 1) {
      const vehicleDriver: IVehicleDriver = {
        id: vehiclesData[index].driverId ? vehiclesData[index].driverId : null,
        name: vehiclesData[index].driverName ? vehiclesData[index].driverName : null,
      };

      const showInNavigatorGroups: IShowInNavigatorGroups = {};
      vehiclesData[index].vehicleGroups.forEach((groupId: number) => {
        showInNavigatorGroups[groupId] = true;
      });

      const onlinePanel = vehiclesData[index].onlinePanel;
      let lastPublicvehiclePosition: IVehiclePosition | null = null;

      if (onlinePanel) {
        lastPublicvehiclePosition = {
          latitude: vehiclesData[index].latitude as number,
          longitude: vehiclesData[index].longitude as number,
          altitude: onlinePanel.altitude,
          angle: onlinePanel.angle || randomAngle(),
          timestamp: vehiclesData[index].timestamp,
          address: onlinePanel.address,
        };
      }

      const vehicle: IVehicle = {
        showInNavigatorGroups,
        id: vehiclesData[index].vehicleId,
        name: vehiclesData[index].name,
        licenceNumber: vehiclesData[index].licenceNumber,
        vehicleTypeId: vehiclesData[index].vehicleTypeId,
        customerId: vehiclesData[index].customerId,
        vehicleGroups: vehiclesData[index].vehicleGroups,
        driver: vehicleDriver,
        showInNavigator: true,
        drivingMode: vehiclesData[index].drivingMode,
      };

      let vehiclePosition: IVehiclePosition | null = null;
      if (vehiclesData[index].latitude && vehiclesData[index].longitude && onlinePanel) {
        vehiclePosition = {
          latitude: vehiclesData[index].latitude as number,
          longitude: vehiclesData[index].longitude as number,
          angle: onlinePanel.angle || randomAngle(),
          altitude: onlinePanel.altitude,
          timestamp: vehiclesData[index].timestamp,
          address: onlinePanel.address,
          distanceCumulative: 0,
        };
      } else if (vehiclesData[index].latitude && vehiclesData[index].longitude && !onlinePanel) {
        vehiclePosition = {
          latitude: vehiclesData[index].latitude as number,
          longitude: vehiclesData[index].longitude as number,
          angle: randomAngle(),
          altitude: 0,
          timestamp: '',
          address: null,
          distanceCumulative: 0,
        };
      }

      let vehicleLastRide: IVehicleLastRide | null = null;
      if (vehiclesData[index].lastRide) {
        vehicleLastRide = {
          start: {
            timestamp: vehiclesData[index].lastRide?.start.timestamp || '',
            latitude: vehiclesData[index].lastRide?.start.latitude || 0.0,
            longitude: vehiclesData[index].lastRide?.start.longitude || 0.0,
            address: vehiclesData[index].lastRide?.start.address || '',
          },
          end: vehiclesData[index]?.lastRide?.end || null,
        };
      }

      vehicles.push(vehicle);
      if (vehiclePosition) {
        vehiclePositions.push(vehiclePosition);
      }

      if (onlinePanel && vehiclesData[index].latitude && vehiclesData[index].longitude) {
        const onlinePanelDataItem: IVehicleOnlinePanelState = {
          vehicleId: vehiclesData[index].vehicleId,
          altitude: onlinePanel.altitude,
          battery: onlinePanel.battery,
          engineTemperature: onlinePanel.engineTemperature,
          fuelLevelLiters: onlinePanel.fuelLevelLiters,
          fuelLevelPercentage: onlinePanel.fuelLevelPercentage,
          rpm: onlinePanel.rpm,
          satellites: onlinePanel.satellites,
          speed: onlinePanel.speed,
          throttle: onlinePanel.throttle,
          ecoDriveScore: onlinePanel.ecoDriveScore,
          timestamp: vehiclesData[index].timestamp,
          latitude: vehiclesData[index].latitude as number,
          longitude: vehiclesData[index].longitude as number,
          address: onlinePanel.address,
          announcements: onlinePanel.announcements,
          ignition: vehiclesData[index].ignition,
          onCng: onlinePanel.onCng,
          onCruise: onlinePanel.onCruise,
          onLpg: onlinePanel.onLpg,
          remoteLock: onlinePanel.remoteLock,
          webasto: null,
          pto: onlinePanel.pto,
          engineState: onlinePanel.engineState,
          electric: onlinePanel.electric,
          mileage: onlinePanel.mileage,
          code: '',
          seatbelts: onlinePanel.seatbelts,
          coolantLevelLow: onlinePanel.coolantLevelLow,
          oilLevelLow: onlinePanel.oilLevelLow,
          checkEngine: onlinePanel.checkEngine,
        };
        onlinePanelData.push(onlinePanelDataItem);
      }

      const vehicleData: IVehicleData = {
        vehicleId: vehiclesData[index].vehicleId,
        position: vehiclePosition,
        status: vehiclesData[index].status as TVehicleStatus,
        lastRide: vehicleLastRide,
        templateId: vehiclesData[index].templateId,
        drivingMode: vehiclesData[index].drivingMode,
      };

      data.push(vehicleData);
    }

    return {
      vehicles,
      vehiclesData: data,
      usedWidgets,
      onlineMapTemplate,
      onlinePanelData,
    };
  }

  return null;
};

export const getSalesChannels = async (): Promise<IBranch[] | null> => {
  const response = await ApiService.get('/v1/user/sales-channel/list', {});
  if (response.data && response.data.status === RESPONSE_OK) {
    const branches: IBranch[] = response.data.salesChannels.map(
      (branchData: ISalesChannelListResponseData) => {
        const branch: IBranch = {
          id: branchData.id,
          name: branchData.name,
        };
        return branch;
      }
    );
    return branches;
  }
  return null;
};

export const saveSelectedCustomers = async (selectedCustomers: number[]): Promise<any> => {
  const data = {
    selected_customers: selectedCustomers,
  };
  const response = await ApiService.post('v1/user-settings/selected_customers/save', data);
  if (response.data && response.data.status === RESPONSE_OK) {
    return response.data;
  }
  return false;
};

export const isVehicleSelected = (selectedVehicles: number[], vehicleId: number): boolean => {
  return selectedVehicles.includes(vehicleId);
};

export const getSelectedCustomers = (
  customers: ICustomer[],
  selectedCustomers: number[]
): ICustomer[] => {
  const defaultCustomer = getCustomerId();
  // default customer always first
  return customers
    .filter((customer) => selectedCustomers.includes(customer.id))
    .sort((a, b) => {
      if (a.id === defaultCustomer) return -1;
      if (b.id === defaultCustomer) return 1;
      return a.id - b.id;
    });
};

export const getSelectedVehicles = (actualSelectedVehicles: number[]): number[] => {
  // const vehicleElements = document.querySelectorAll('.customer-vehicles .car-item');
  const vehicleElements = document.querySelectorAll('.b-navigator-listing-item-vehicle');

  const selectedVehicles: number[] = [];
  vehicleElements.forEach((element: Element, index: number) => {
    if (
      Config.selectedVehiclesLimit.enabled &&
      selectedVehicles.length + 1 > Config.selectedVehiclesLimit.limit
    ) {
      if (isEqual(actualSelectedVehicles, selectedVehicles)) {
        return false;
      }

      showMessage(
        'navigator.toastr.selectedVehicleLimit.title',
        'navigator.toastr.selectedVehicleLimit.message',
        'warning',
        null,
        { limit: Config.selectedVehiclesLimit.limit }
      );

      return false;
    }
    const vehicleId = (element as HTMLDivElement).dataset.vehicleId;
    if (!selectedVehicles.includes(Number(vehicleId))) {
      selectedVehicles.push(Number(vehicleId));
    }

    // if (element instanceof HTMLElement) {
    //   if (element.style.display !== 'none' && vehicleId) {
    //     const id = parseInt(vehicleId.substr(8), 10);
    //     selectedVehicles.push(id);
    //   }
    // }
    return true;
  });
  if (isEqual(actualSelectedVehicles, selectedVehicles)) {
    return [];
  }
  return selectedVehicles;
};

export const setShowAllVehicles = (
  state: IVehicles,
  vehicleStatuses: IVehicleStatuses,
  status: TVehicleStatus | null,
  customerId: number
): { vehicles: IVehicles; selectedVehicles: number[] } => {
  const vehicles: IVehicles = {};
  const vehicleKeys: string[] = Object.keys(state);
  const selectedVehicles: number[] = [];

  if (status && customerId === 0) {
    vehicleKeys.forEach((vehicleId: string) => {
      const vehicle = state[Number(vehicleId)];
      const vehicleStatus = vehicleStatuses[Number(vehicleId)];
      if (vehicle && vehicleStatus) {
        if (vehicleStatus.status !== status && vehicleStatus.drivingMode === 'business') {
          const newVehicle = {
            ...vehicle,
            showInNavigator: false,
          };
          vehicles[vehicle.id] = newVehicle;
        } else if (
          vehicleStatus.status !== status &&
          vehicleStatus.drivingMode === 'private' &&
          status === PRIVATE_STATUS
        ) {
          const newVehicle = {
            ...vehicle,
            showInNavigator: true,
          };
          selectedVehicles.push(Number(vehicleId));

          vehicles[vehicle.id] = newVehicle;
        } else if (vehicleStatus.drivingMode === 'business') {
          const newVehicle = {
            ...vehicle,
            showInNavigator: true,
          };
          selectedVehicles.push(Number(vehicleId));
          vehicles[vehicle.id] = newVehicle;
        } else {
          const newVehicle = {
            ...vehicle,
            showInNavigator: false,
          };
          vehicles[vehicle.id] = newVehicle;
        }
      }
    });
  }

  if (status && customerId > 0) {
    vehicleKeys.forEach((vehicleId: string) => {
      const vehicle = state[Number(vehicleId)];
      const vehicleStatus = vehicleStatuses[Number(vehicleId)];
      if (vehicle && vehicleStatus) {
        if (
          vehicleStatus.status !== status &&
          vehicle.customerId === customerId &&
          vehicleStatus.drivingMode === 'business'
        ) {
          const newVehicle = {
            ...vehicle,
            showInNavigator: false,
          };
          vehicles[vehicle.id] = newVehicle;
        } else if (
          vehicleStatus.status !== status &&
          vehicleStatus.drivingMode === 'private' &&
          status === PRIVATE_STATUS &&
          vehicle.customerId === customerId
        ) {
          const newVehicle = {
            ...vehicle,
            showInNavigator: true,
          };
          selectedVehicles.push(Number(vehicleId));
          vehicles[vehicle.id] = newVehicle;
        } else if (vehicleStatus.drivingMode === 'business' && vehicle.customerId === customerId) {
          const newVehicle = {
            ...vehicle,
            showInNavigator: true,
          };
          selectedVehicles.push(Number(vehicleId));
          vehicles[vehicle.id] = newVehicle;
        } else if (vehicle.customerId !== customerId) {
          vehicles[vehicle.id] = vehicle;
        } else {
          const newVehicle = {
            ...vehicle,
            showInNavigator: false,
          };
          vehicles[vehicle.id] = newVehicle;
        }
      }
    });
  }

  if (!status && customerId === 0) {
    vehicleKeys.forEach((vehicleId: string) => {
      const vehicle = state[Number(vehicleId)];
      if (vehicle) {
        if (!vehicle.showInNavigator) {
          const newVehicle = {
            ...vehicle,
            showInNavigator: true,
          };
          selectedVehicles.push(Number(vehicleId));
          vehicles[vehicle.id] = newVehicle;
        } else {
          selectedVehicles.push(Number(vehicleId));
          vehicles[vehicle.id] = vehicle;
        }
      }
    });
  }

  if (!status && customerId > 0) {
    vehicleKeys.forEach((vehicleId: string) => {
      const vehicle = state[Number(vehicleId)];
      if (vehicle) {
        if (vehicle.customerId === customerId) {
          const newVehicle = {
            ...vehicle,
            showInNavigator: true,
          };
          selectedVehicles.push(Number(vehicleId));
          vehicles[vehicle.id] = newVehicle;
        } else {
          vehicles[vehicle.id] = vehicle;
        }
      }
    });
  }

  const newState: IVehicles = { ...vehicles };

  return { vehicles: newState, selectedVehicles };
};

export const setShowAllVehiclesByGroup = (
  state: IVehicles,
  vehicleStatuses: IVehicleStatuses,
  status: TVehicleStatus | null,
  groupId: number
): { vehicles: IVehicles; selectedVehicles: number[] } => {
  const vehicles: IVehicles = {};
  const vehicleKeys: string[] = Object.keys(state);
  const selectedVehicles: number[] = [];

  if (status && groupId) {
    vehicleKeys.forEach((vehicleId: string) => {
      const vehicle = state[Number(vehicleId)];
      const vehicleStatus = vehicleStatuses[Number(vehicleId)];
      if (
        vehicle &&
        vehicleStatus &&
        vehicleStatus.status !== status &&
        vehicle.vehicleGroups &&
        vehicle.vehicleGroups.includes(groupId) &&
        vehicleStatus.drivingMode === 'private' &&
        status !== PRIVATE_STATUS
      ) {
        const newVehicle = {
          ...vehicle,
          showInNavigatorGroups: {
            [String(groupId)]: false,
          },
        };
        vehicles[Number(vehicleId)] = newVehicle;
      } else if (
        vehicle &&
        vehicleStatus &&
        vehicleStatus.status !== status &&
        vehicle.vehicleGroups &&
        vehicle.vehicleGroups.includes(groupId) &&
        vehicleStatus.drivingMode === 'business'
      ) {
        const newVehicle = {
          ...vehicle,
          showInNavigatorGroups: {
            [String(groupId)]: false,
          },
        };
        vehicles[Number(vehicleId)] = newVehicle;
      } else if (
        vehicle &&
        vehicleStatus &&
        vehicleStatus.status === status &&
        vehicleStatus.drivingMode === 'business' &&
        vehicle.vehicleGroups &&
        vehicle.vehicleGroups.includes(groupId)
      ) {
        const newVehicle = {
          ...vehicle,
          showInNavigatorGroups: {
            [String(groupId)]: true,
          },
        };
        selectedVehicles.push(Number(vehicleId));
        vehicles[Number(vehicleId)] = newVehicle;
      } else if (
        vehicle &&
        vehicleStatus &&
        vehicleStatus.status !== status &&
        vehicleStatus.drivingMode === 'private' &&
        status === PRIVATE_STATUS &&
        vehicle.vehicleGroups &&
        vehicle.vehicleGroups.includes(groupId)
      ) {
        const newVehicle = {
          ...vehicle,
          showInNavigatorGroups: {
            [String(groupId)]: true,
          },
        };
        selectedVehicles.push(Number(vehicleId));
        vehicles[Number(vehicleId)] = newVehicle;
      } else {
        vehicles[Number(vehicleId)] = vehicle;
      }
    });
  }

  if (!status && groupId) {
    vehicleKeys.forEach((vehicleId: string) => {
      const vehicle = state[Number(vehicleId)];
      if (vehicle.vehicleGroups && vehicle.vehicleGroups.includes(groupId)) {
        const newVehicle = {
          ...vehicle,
          showInNavigatorGroups: {
            [String(groupId)]: true,
          },
        };
        selectedVehicles.push(Number(vehicleId));
        vehicles[Number(vehicleId)] = newVehicle;
      } else {
        // selectedVehicles.push(Number(vehicleId));
        vehicles[Number(vehicleId)] = vehicle;
      }
    });
  }

  return { vehicles, selectedVehicles };
};
