import { atom, atomFamily } from 'recoil';
import {
  ICmdTableActionFilter,
  ICmdTableColumn,
  ICmdTableColumnsKeyOrder,
  ICmdTableFilter,
  ICmdTableSettingsData,
  ICmdTableSortFilter,
} from './types';

export const sortFilterAtomFamily = atomFamily<ICmdTableSortFilter, string>({
  key: 'cmdTableSortFilter',
  default: {},
});

export const filterForRequestAtomFamily = atomFamily<ICmdTableFilter, string>({
  key: 'cmdTableFilterForRequest',
  default: {},
});
export const tableFiltersAtomFamily = atomFamily<string[], string>({
  key: 'tableFilters',
  default: [],
});

export const tableFilterAtomFamily = atomFamily<
  ICmdTableActionFilter | null,
  { [key: string]: string }
>({
  key: 'cmdTableFilter',
  default: null,
});

export const isTableSettingsMenuOpenedAtom = atom<boolean>({
  key: 'cmdTableIsSettingsMenuOpened',
  default: false,
});

export const columnsKeyOrderAtom = atom<ICmdTableColumnsKeyOrder[]>({
  key: 'cmdTableColumnKeyOrder',
  default: [],
});

export const tableSettingsAtom = atom<ICmdTableSettingsData | null>({
  key: 'cmdTableSettings',
  default: null,
});
export const columnsAtom = atom<ICmdTableColumn[]>({
  key: 'cmdTableColumns',
  default: [],
});
