import * as React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'wouter';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useQueries, useQuery } from '@tanstack/react-query';
import { CmdLoader } from '@commander-services/gui-components';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayjs from 'dayjs';
import { TABLE_CAN_REFUELING, TABLE_REFUELING } from '../../../Services/TableService';
import PermissionsService, { PERMISSION_CAN_REFUELING } from '../../../Services/PermissionsService';
import { URL_CAN_REFUELINGS } from '../../../router/constants';
import useUrlQueryParams from '../../../hooks/useUrlQueryParams';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import OnlineHelp from '../../OnlineHelp';
import TabComponent from '../../TabComponent';
import CmdTableSettings from '../../CmdTable/settings/CmdTableSettings';
import {
  CAN_REFUELINGS_ACCOUNTING_KEY,
  CAN_REFUELINGS_AMOUNT_KEY,
  CAN_REFUELINGS_CUSTOMER_KEY,
  CAN_REFUELINGS_DUPLICATE_KEY,
  CAN_REFUELINGS_FUEL_TYPE_KEY,
  CAN_REFUELINGS_LICENSE_NUMBER_KEY,
  CAN_REFUELINGS_MODIFIED_AT_KEY,
  CAN_REFUELINGS_MODIFIED_BY_KEY,
  CAN_REFUELINGS_RIDE_KEY,
  CAN_REFUELINGS_STATUS_KEY,
  CAN_REFUELINGS_TABLE_KEY,
  CAN_REFUELINGS_TABLE_SETTINGS,
  CAN_SOURCE,
  CanRefuelingDataToConvert,
  CanRefuelingsRecord,
  CanRefuelingsResponse,
  CanRefuelingsTableSettingsKeys,
} from './types';
import FilterChips from '../../CmdTable/FilterChips/FilterChips';
import useCanRefuelings from './useCanRefuelings';
import { ICmdTableColumn, ICmdTableFilter } from '../../CmdTable/types';
import { IDatepickerSettings, setTableDatepickerAtom } from '../../../store/recoil/TableState';
import { customersAtom, ICustomer } from '../../../store/recoil/customers';
import TableDatepicker from '../../TableDatepicker';
import { getDayjsLocale, getDefaultLanguage } from '../../../Services/LanguageService';
import { ACTIONS_KEY } from '../../CmdTable/constants';
import RefuelingForm from '../RefuelingsForm';
import {
  getCurrencyByCustomerId,
  getFormField,
  getRidesByVehicleId,
  getValidation,
  getVatByCustomerId,
  save,
} from '../RefuelingService';
import { IOption } from '../../Forms/CmdField';
import { CurrencyIdApiResponse, RefuelingsFormValues, VatApiResponse } from '../types';
import LoaderService from '../../../Services/LoaderService';
import { VehiclesBasicInfo } from '../../Vehicles/types';
import { tabsByPermission } from './CanRefuelingsService';
import { CmdTableWithSelectedVehicles } from '../../CmdTable/CmdTableWithSelectedVehicles';
import { getTableName, getTableSettings, handleOrderColumns } from '../../CmdTable/CmdTableService';
import { vehiclesBasicInfoAtom } from '../../../store/recoil/vehicles';
import {
  columnsAtom,
  filterForRequestAtomFamily,
  tableFiltersAtomFamily,
} from '../../CmdTable/CmdTableState';
import useAnalytics from '../../../hooks/useAnalytics';



// const fetchSize = 50;

function CanRefuelings() {
  dayjs.extend(weekOfYear);
  const { formatMessage: f } = useIntl();
  const [filterForRequest, setFilterForRequest] = useRecoilState<ICmdTableFilter>(
    filterForRequestAtomFamily(CAN_REFUELINGS_TABLE_KEY)
  );
  const vehicles = useRecoilValue<VehiclesBasicInfo>(vehiclesBasicInfoAtom);
  const tableFiltersKey = useRecoilValue(tableFiltersAtomFamily(CAN_REFUELINGS_TABLE_KEY));
  const [isSubpageOpened, setIsSubpageOpened] = React.useState<boolean>(false);
  const [pathname, navigate] = useLocation();
  const urlQuery = useUrlQueryParams();
  const { setDocumentTitle } = useDocumentTitle();
  const tabs = React.useState(
    tabsByPermission([TABLE_REFUELING, TABLE_CAN_REFUELING], TABLE_CAN_REFUELING)
  )[0];
  const { columns, columnOrder, columnVisibility } = useCanRefuelings();
  const [datepickerSettings, setDatepickerSettings] = useRecoilState<IDatepickerSettings | null>(
    setTableDatepickerAtom
  );
  const [minDatepickerTimestamp, setMinDatepickerTimestamp] = React.useState<Date>(new Date());
  const [maxDatepickerTimestamp, setMaxDatepickerTimestamp] = React.useState<Date>(new Date());
  const customers = useRecoilValue(customersAtom);
  const setColumnsTable = useSetRecoilState<ICmdTableColumn[]>(columnsAtom);
  const { trackEvent } = useAnalytics();

  const handleSetOrderColumns = (
    newOrderColumns: { name: string; hidden: boolean; visibility: string; sort: boolean }[],
    customerForSettings: ICustomer[]
  ) => {
    const orderColumns = handleOrderColumns(newOrderColumns, customerForSettings);

    setColumnsTable(orderColumns);
  };

  const getSettings = async () => {
    const settings = await getTableSettings(CAN_REFUELINGS_TABLE_KEY);
    if (settings) {
      handleSetOrderColumns(settings.columns, customers);
      return settings;
    }
    return false;
  };

  const results = useQueries({
    queries: [
      {
        queryKey: ['canRefuelingsCmdTableSettings'],
        queryFn: () => getSettings(),
        staleTime: 0,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    ],
  });

  const { isLoading: isLoadingSettings } = results[0];

  React.useEffect(() => {
    if (
      datepickerSettings &&
      datepickerSettings[CAN_REFUELINGS_ACCOUNTING_KEY] &&
      datepickerSettings[CAN_REFUELINGS_ACCOUNTING_KEY].date &&
      datepickerSettings[CAN_REFUELINGS_ACCOUNTING_KEY].date.length === 2 // &&
    ) {
      const newMinTimestamp = datepickerSettings.accountingTs.date[0];
      const newMaxTimestamp = datepickerSettings.accountingTs.date[1];

      const isMinDateChanged =
        dayjs(newMinTimestamp).format('DD.MM.YYYY HH:mm') !==
        dayjs(minDatepickerTimestamp).format('DD.MM.YYYY HH:mm');
      const isMaxDateChanged =
        dayjs(newMaxTimestamp).format('DD.MM.YYYY HH:mm') !==
        dayjs(maxDatepickerTimestamp).format('DD.MM.YYYY HH:mm');

      if (isMinDateChanged) {
        setMinDatepickerTimestamp(newMinTimestamp);
      }

      if (isMaxDateChanged) {
        setMaxDatepickerTimestamp(newMaxTimestamp);
      }

      if (isMinDateChanged || isMaxDateChanged) {
        setFilterForRequest((prevFilter: ICmdTableFilter) => ({
          ...prevFilter,
          accountingTs: {
            min: newMinTimestamp,
            max: newMaxTimestamp,
          },
        }));
      }
    }
  }, [datepickerSettings, maxDatepickerTimestamp, minDatepickerTimestamp, setFilterForRequest]);

  React.useEffect(() => {
    if (pathname === URL_CAN_REFUELINGS) {
      setIsSubpageOpened(false);
      // setCustomerId(null);
      const documentTitle = `${f({ id: 'menu.canRefuelingsOverview' })} - ${f({
        id: 'menu.refuelings',
      })}`;
      setDocumentTitle(documentTitle);
    } else if (pathname === `${URL_CAN_REFUELINGS}/convert-to-refueling`) {
      setIsSubpageOpened(true);
      const documentTitle = `${f({ id: 'table.convertToRefueling' })} - ${f({
        id: 'menu.canRefuelingsOverview',
      })} - ${f({
        id: 'menu.refuelings',
      })}`;
      setDocumentTitle(documentTitle);
    }
    const regex = /\/refuelings\/(\d+)/;
    if (regex.test(pathname)) {
      const documentTitle = `${f({
        id: 'menu.canRefuelingsOverview',
      })} - ${f({
        id: 'menu.refuelings',
      })}`;
      setDocumentTitle(documentTitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, setIsSubpageOpened]);

  let formData: CanRefuelingDataToConvert | null = null;
  if (
    urlQuery.get('vehicleId') &&
    urlQuery.get('customerId') &&
    urlQuery.get('rideId') &&
    urlQuery.get('fuelTypeId') &&
    urlQuery.get('amount') &&
    urlQuery.get('accountingTs') &&
    urlQuery.get('refuelingCanId')
  ) {
    formData = {
      vehicleId: Number(urlQuery.get('vehicleId')),
      customerId: Number(urlQuery.get('customerId')),
      rideId: String(urlQuery.get('rideId')),
      fuelTypeId: Number(urlQuery.get('fuelTypeId')),
      amount: Number(urlQuery.get('amount')),
      accountingTs: String(urlQuery.get('accountingTs')),
      source: CAN_SOURCE,
      refuelingCanId: Number(urlQuery.get('refuelingCanId')),
    };
  }

  const getMinDatepickerValue = () => {
    const date = new Date(new Date().setHours(0, 0, 0, 0));
    date.setMonth(date.getMonth() - 24);
    return date;
  };

  const getDatepickerValue = (index: number) => {
    const value =
      !datepickerSettings ||
      !datepickerSettings[CAN_REFUELINGS_ACCOUNTING_KEY] ||
      !datepickerSettings[CAN_REFUELINGS_ACCOUNTING_KEY].date[index]
        ? ''
        : datepickerSettings[CAN_REFUELINGS_ACCOUNTING_KEY].date[index];
    if (value) {
      const defaultLanguage = getDefaultLanguage();
      return dayjs(value).locale(getDayjsLocale(defaultLanguage)).format('D.M.YYYY HH:mm');
    }
    return '';
  };

  const { data: validationData, isLoading: isLoadingData } = useQuery({
    queryKey: ['refuelingValidation'],
    queryFn: () => getValidation(),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: isSubpageOpened, // pathname === `${URL_CAN_REFUELINGS}/convert-to-refueling`,
  });

  const getRideIdOptions = async (vehicleId: number): Promise<IOption[] | false> => {
    if (!formData?.customerId) {
      return false;
    }
    const accountingTs = urlQuery.get('accountingTs');
    const response = await getRidesByVehicleId(formData.customerId, vehicleId, accountingTs);
    return response;
  };

  const getFuelTypeIdOptions = async (custId: number): Promise<IOption[] | false> => {
    const response = await getFormField('fuelTypeId', custId);
    return response;
  };

  const getCurrencyIdOptions = async (custId: number): Promise<IOption[] | false> => {
    const response = await getFormField('secondaryCurrencyId', custId);
    return response;
  };

  const getCustomerCurrencyIdOption = async (
    custId: number
  ): Promise<CurrencyIdApiResponse | false> => {
    const response = await getCurrencyByCustomerId(custId);
    return response;
  };

  const getGasStationNetworkIdOptions = async (custId: number): Promise<IOption[] | false> => {
    const response = await getFormField('gasStationNetworkId', custId);
    return response;
  };

  const getVatOption = async (custId: number): Promise<VatApiResponse | false> => {
    const response = await getVatByCustomerId(custId);
    return response;
  };

  const handleClose = () => {
    setIsSubpageOpened(false);
    navigate(URL_CAN_REFUELINGS);
    trackEvent([`table ${getTableName(CAN_REFUELINGS_TABLE_KEY)}`, 'click', 'Close can refueling']);
  };

  const handleCreate = async (values: RefuelingsFormValues) => {
    LoaderService.showLoader();
    const response = await save(values);
    if (response) {
      handleClose();
      // updateTable();
    }
    LoaderService.showLoader(false);
  };

  // Memoizing unstable props
  const allowedRange = React.useMemo(
    () => [getMinDatepickerValue(), new Date(new Date().setHours(23, 59, 59, 999))],
    []
  );

  const sort = React.useMemo(
    () => ({ column: CAN_REFUELINGS_ACCOUNTING_KEY, direction: 'desc' }),
    []
  );

  const stickyColumns = React.useMemo(() => [ACTIONS_KEY], []);

  const lockedColumns = React.useMemo(
    () => [
      CAN_REFUELINGS_STATUS_KEY,
      CAN_REFUELINGS_CUSTOMER_KEY,
      CAN_REFUELINGS_FUEL_TYPE_KEY,
      CAN_REFUELINGS_LICENSE_NUMBER_KEY,
      CAN_REFUELINGS_AMOUNT_KEY,
      CAN_REFUELINGS_DUPLICATE_KEY,
      CAN_REFUELINGS_MODIFIED_AT_KEY,
      CAN_REFUELINGS_MODIFIED_BY_KEY,
      CAN_REFUELINGS_RIDE_KEY,
      ACTIONS_KEY,
    ],
    []
  );

  const customNoDataMessage = React.useMemo(
    () => ({
      title: f({ id: 'table.noData.title' }),
      text: f({ id: 'table.noData.text' }),
    }),
    [f]
  );

  return (
    <>
      <div className="col-12" style={{ display: isSubpageOpened ? 'none' : '' }}>
        <div className="row section page-head">
          <h4>
            {`${f({ id: 'menu.refuelings' })} / ${f({ id: 'menu.refuelingsOverview' })}`}
            <OnlineHelp topic="refuelings" />
          </h4>
        </div>
        {!isSubpageOpened && (
          <TabComponent tabs={tabs}>
            <section className="basic-box border-top-thin border-top-gray">
              <div className="basic-box__content">
                <div className="row no-gutters mb-2">
                  <div className="col-12 col-md-auto mr-md-2 mb-2" />
                  <div className="col col-md" />
                  <div className="col-auto col-md-12 mt-2 ml-2 mt-md-0 ml-md-0 col-lg-auto order-5 order-lg-4 mt-lg-2 mr-2" />
                  <div className="col-auto order-4 order-lg-5">
                    <CmdTableSettings
                      tableId={CAN_REFUELINGS_TABLE_KEY}
                      tableName={CAN_REFUELINGS_TABLE_SETTINGS}
                      sort={sort}
                      translationKeys={CanRefuelingsTableSettingsKeys}
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap mb-2">
                  <TableDatepicker
                    name={CAN_REFUELINGS_ACCOUNTING_KEY}
                    allowedRange={allowedRange}
                    isActive={true}
                    type="chip"
                  >
                    <div
                      className="label label-primary mr-1 mb-1 label--filter"
                      style={{ cursor: 'pointer', position: 'relative' }}
                      data-cy="datapicker-filter-accountingTs"
                    >
                      <span>
                        {`${f({ id: 'costsOverviewForm.dateTime' })}: ${getDatepickerValue(
                          0
                        )} - ${getDatepickerValue(1)}`}
                      </span>
                    </div>
                  </TableDatepicker>
                  {tableFiltersKey &&
                    tableFiltersKey.map((filterId) => (
                      <FilterChips
                        key={filterId}
                        id={filterId}
                        tableName={CAN_REFUELINGS_TABLE_KEY}
                      />
                    ))}
                </div>
                {(isLoadingSettings || isLoadingData) && <CmdLoader inContent={true} />}
                {filterForRequest?.accountingTs && (
                  <CmdTableWithSelectedVehicles<CanRefuelingsResponse, CanRefuelingsRecord>
                    name={CAN_REFUELINGS_TABLE_KEY}
                    stickyColumns={stickyColumns}
                    lockedColumns={lockedColumns}
                    columns={columns}
                    columnOrder={columnOrder}
                    columnVisibility={columnVisibility}
                    customNoDataMessage={customNoDataMessage}
                  />
                )}
              </div>
            </section>
          </TabComponent>
        )}
      </div>
      {isSubpageOpened &&
        validationData &&
        Object.values(vehicles).length > 0 &&
        formData &&
        PermissionsService.hasAccess(PERMISSION_CAN_REFUELING, ['update'], formData.customerId) && (
          <RefuelingForm
            data={formData}
            validation={validationData}
            onCancelCallback={handleClose}
            onCreateCallback={handleCreate}
            additionalData={{
              getRideIdOptions,
              getCurrencyIdOptions,
              getFuelTypeIdOptions,
              getGasStationNetworkIdOptions,
              vehicles,
              getCustomerCurrencyIdOption,
              getVatOption,
            }}
          />
        )}
    </>
  );
}

export default CanRefuelings;
