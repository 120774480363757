import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  CmdRadioButton,
  CmdSimpleSelect,
  CmdChipSelect,
  CmdCheckboxGroup,
  CmdLabel,
  CmdCheckbox,
} from '@commander-services/gui-components';
import CmdBox from '@commander-services/cmd-box';
import { Controller, useForm } from 'react-hook-form';
import { useQueries } from '@tanstack/react-query';
import FormikValidatorService from '../../Services/FormikValidatorService';
import Section, { IFormSectionButton } from '../Forms/Section';
import { reportSettingsOptionsR06, reportSettingsOptionsR06EcoDrive } from './ReportSettings';
import { IOption } from '../Forms/CmdField';
import usePageWithForm from '../../hooks/usePageWithForm';
import Calendar from '../Forms/ReactHookFormFields/Calendar';
import { DRIVER_ENTITY, VEHICLE_ENTITY } from '.';
import { IReportItem } from './types';
import {
  getDrivers,
  getValidationWithEntity,
  getVehicles,
  handleAllowedRange,
  handleDatepickerValidation,
  handleReportSettings,
} from './ReportService';
import { transformErrors } from '../../Services/HookFormService';
import useAnalytics from '../../hooks/useAnalytics';

interface IReportR06InputForm {
  reportId: string;
  vehicleIds: string[];
  driverIds: string[];
  sections: string[];
  format: string;
  date: Date[];
  mergeBy: string;
  entity: string;
  violations: string[];
}

interface IReportForm {
  handleReportId: (id: string) => void;
  handleMainButton: (indexId: string, value: boolean) => void;
  selectedReportId: string;
  reportsList: IReportItem[];
  buttonDisabled: boolean;
  handleDownload: (url: string, values: any) => void;
}

export default function ReportR06(props: IReportForm) {
  usePageWithForm();
  const { formatMessage: f } = useIntl();
  const [validation, setValidation] = React.useState<any>({});
  const parseDateToday0 = new Date(new Date().setHours(0, 0, 0, 0));
  const parseDateToday24 = new Date(new Date().setHours(23, 59, 0, 0));
  const [vehicleOptions, setVehicleOptions] = React.useState<IOption[]>([]);
  const [reportRange, setReportRange] = React.useState('');
  const [reportActionUrl, setReportActionUrl] = React.useState('');
  const [bossCheckboxValue, setBossCheckboxValue] = React.useState('');
  const { trackEvent } = useAnalytics();

  const getReportSettingsOptions = () => {
    return reportSettingsOptionsR06.map((item: string) => {
      return { value: item, item: f({ id: `reports.R06.${item}` }) };
    });
  };

  const getReportSettingsOptionsEcoDrive = () => {
    return reportSettingsOptionsR06EcoDrive.map((item: string) => {
      return { value: item, item: f({ id: `reports.R06.${item}` }) };
    });
  };

  const validateFields = (values: any) => {
    if (values) {
      props.handleMainButton('isDisabled', false);
      props.handleMainButton('hasError', false);
    }
    let formErrors: any = {};
    if (!validation) {
      return formErrors;
    }
    formErrors = FormikValidatorService.validateByValidationApiResponse(values, validation);
    const message = handleDatepickerValidation(values?.date, props.selectedReportId, reportRange);
    if (message.length > 0) {
      formErrors.date = f({
        id: message,
      });
    }
    return transformErrors(formErrors);
  };

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    trigger,
    setError,
    formState: { errors },
  } = useForm<IReportR06InputForm>({
    mode: 'onChange',
    defaultValues: {
      reportId: props.selectedReportId,
      mergeBy: '',
      format: 'xlsx',
      date: [parseDateToday0, parseDateToday24],
      sections: [],
      vehicleIds: [],
      driverIds: [],
      entity: '',
      violations: [],
    },
    resolver: async (data) => {
      const formErrors = validateFields(data);
      return {
        values: formErrors && Object.keys(formErrors).length ? {} : data, // if there's an error, return empty values
        errors: formErrors,
      };
    },
  });

  const onSubmit = async (values: any) => {
    if (reportActionUrl) {
      props.handleMainButton('isDisabled', true);
      const newValues = { ...values };
      newValues.violations = values.violations.length !== 0;
      newValues.dateFrom = values.date[0];
      newValues.dateTo = values.date[1];
      delete newValues.date;
      props.handleDownload(reportActionUrl, newValues);
      trackEvent(['reports', 'click', 'Report R06 submitted']);
    }
  };

  const getReportValidation = async () => {
    const responseData = await getValidationWithEntity(props.selectedReportId, watch('entity'));
    if (responseData) {
      setReportActionUrl(responseData?.actionUrl || '');
      setReportRange(responseData?.range || '');
      setValidation(responseData.form);
      setValue(
        'sections',
        responseData?.userSettings?.sections
          ? responseData.userSettings.sections.split(',')
          : reportSettingsOptionsR06EcoDrive
      );
      setValue(
        'violations',
        handleReportSettings(responseData.userSettings, reportSettingsOptionsR06)
      );
      if (!watch('entity')) {
        if (responseData.userSettings && responseData.userSettings.entity) {
          setValue('entity', responseData.userSettings.entity);
        } else {
          setValue('entity', VEHICLE_ENTITY);
        }
      }
    }
  };

  const getReportVehicles = async () => {
    const options: IOption[] | false = await getVehicles(props.selectedReportId);
    if (options) {
      setVehicleOptions(options);
    }
  };

  const getReportDrivers = async () => {
    const options: IOption[] | false = await getDrivers(props.selectedReportId);
    if (options) {
      setVehicleOptions(options);
    }
  };

  useQueries({
    queries: [
      {
        queryKey: ['reportR06Validation', watch('entity')],
        queryFn: () => getReportValidation(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
      {
        queryKey: ['reportR06Vehicles'],
        queryFn: () => getReportVehicles(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: watch('entity') === VEHICLE_ENTITY,
      },
      {
        queryKey: ['reportR06Drivers'],
        queryFn: () => getReportDrivers(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: watch('entity') === DRIVER_ENTITY,
      },
    ],
  });

  const handleReportParameter = (value: string) => {
    if (value === 'checked') {
      setValue('violations', reportSettingsOptionsR06);
      setValue('sections', reportSettingsOptionsR06EcoDrive);
      setBossCheckboxValue('checked');
      setError('sections', { message: '' });
      setError('violations', { message: '' });
    } else {
      setValue('violations', []);
      setValue('sections', []);
      setBossCheckboxValue('');
      trigger('sections');
    }
    trackEvent(['reports', 'change', 'Report R06 select all']);
  };

  React.useEffect(() => {
    if (watch('violations').length === 0 && watch('sections').length === 0) {
      setBossCheckboxValue('');
    } else if (
      watch('violations').length + watch('sections').length ===
      reportSettingsOptionsR06.length + reportSettingsOptionsR06EcoDrive.length
    ) {
      setBossCheckboxValue('checked');
    } else {
      setBossCheckboxValue('indeterminate');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('violations'), watch('sections')]);

  const downloadButton: IFormSectionButton = {
    title: f({ id: 'reports.download' }),
    tooltip: f({ id: 'reports.download' }),
    disabled: props.buttonDisabled,
    buttonCallback: handleSubmit(onSubmit),
  };

  return (
    <div className="w-page new-css-wrapper" data-cy="reports-wrapper">
      <form
        className="form-horizontal"
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingTop: '30px' }}
        data-cy="reports-form"
      >
        <div className="container-fluid" data-cy="reports-container">
          <div data-cy="reports-sections">
            <div className="row" data-cy="reports-row">
              <div className="col-xxl-6" data-cy="reports-col">
                <Section
                  id="alarms-basic-information"
                  title={f({ id: 'alarms.section.basicInformation' })}
                  buttons={[downloadButton]}
                >
                  <Controller
                    name="reportId"
                    control={control}
                    rules={{ required: errors.reportId ? errors.reportId.message : undefined }}
                    render={({ field }) => (
                      <CmdSimpleSelect
                        {...field}
                        name="reportId"
                        id="reportId"
                        value={props.selectedReportId}
                        label={f({ id: 'reports.reportId' })}
                        introOption={f({ id: 'suppliersForm.choose' })}
                        options={props.reportsList}
                        required={true}
                        onChangeCallback={(value: string) => {
                          setValue('reportId', value);
                          props.handleReportId(value);
                          trackEvent(['reports', 'change', `Report ${value} changed`]);
                        }}
                        error={errors.reportId?.message}
                        touched={true}
                      />
                    )}
                  />

                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: errors.date ? errors.date.message : undefined }}
                    render={({ field }) => (
                      <Calendar
                        {...field}
                        id="date"
                        name="date"
                        label={f({ id: 'datepicker.range' })}
                        required={true}
                        allowedRange={handleAllowedRange(validation?.date)}
                        note="(dd.mm.rrrr hh:mm)"
                        mode="range"
                        disabledItems={['THIS_YEAR', 'PREVIOUS_YEAR']}
                        error={errors.date?.message}
                        touched={true}
                        onChangeCallback={(value: Date[]) => {
                          setValue('date', value);
                          trigger('date');
                          trackEvent(['reports', 'change', 'Report R06 date changed']);
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="format"
                    control={control}
                    rules={{ required: errors.format ? errors.format.message : undefined }}
                    render={({ field }) => (
                      <CmdRadioButton
                        {...field}
                        id="format"
                        name="format"
                        label={f({ id: 'reports.format' })}
                        options={[{ item: '.xlsx', value: 'xlsx' }]}
                        required={true}
                        disabled={true}
                      />
                    )}
                  />
                  <Controller
                    name="entity"
                    control={control}
                    rules={{ required: errors.entity ? errors.entity.message : undefined }}
                    render={({ field }) => (
                      <CmdRadioButton
                        {...field}
                        name="entity"
                        id="entity"
                        label={f({ id: 'reports.entity' })}
                        options={[
                          { item: f({ id: 'menu.carsSettings' }), value: VEHICLE_ENTITY },
                          { item: f({ id: 'reports.drivers' }), value: DRIVER_ENTITY },
                        ]}
                        required={true}
                        setValue={(value: string) => {
                          setValue('entity', value);
                          trackEvent(['reports', 'change', 'Report R06 entity changed']);
                        }}
                        error={errors.entity?.message}
                        touched={true}
                      />
                    )}
                  />
                  {watch('entity') === VEHICLE_ENTITY && (
                    <Controller
                      name="vehicleIds"
                      control={control}
                      rules={{
                        required: errors.vehicleIds ? errors.vehicleIds.message : undefined,
                      }}
                      render={({ field }) => (
                        <CmdChipSelect
                          {...field}
                          id="vehicleIds"
                          name="vehicleIds"
                          label={f({ id: 'reports.vehicleIds' })}
                          placeholder={f({ id: 'form.choose' })}
                          searchPlaceholder={f({ id: 'form.search' })}
                          selectButton={f({ id: 'form.groupChipSelectUnique.button.apply' })}
                          resetButton={f({ id: 'form.groupChipSelectUnique.button.reset' })}
                          options={vehicleOptions}
                          required={true}
                          searchTooltip={f({
                            id: 'alarms.section.vehicles.vehicleId.search.tooltip',
                          })}
                          selectButtonTooltip={f({
                            id: 'alarms.section.vehicles.vehicleId.selectButton.tooltip',
                          })}
                          resetButtonTooltip={f({
                            id: 'alarms.section.vehicles.vehicleId.resetButton.tooltip',
                          })}
                          bossCheckbox={f({ id: 'reports.chooseAllVehicles' })}
                          error={errors.vehicleIds?.message}
                          touched={true}
                          value={watch('vehicleIds')}
                          setValue={(value: string[]) => {
                            setValue('vehicleIds', value);
                            trigger('vehicleIds');
                            trackEvent(['reports', 'change', 'Report R06 vehicleIds changed']);
                          }}
                        />
                      )}
                    />
                  )}
                  {watch('entity') === DRIVER_ENTITY && (
                    <Controller
                      name="driverIds"
                      control={control}
                      rules={{ required: errors.driverIds ? errors.driverIds.message : undefined }}
                      render={({ field }) => (
                        <CmdChipSelect
                          {...field}
                          id="driverIds"
                          name="driverIds"
                          label={f({ id: 'reports.driverId' })}
                          placeholder={f({ id: 'form.choose' })}
                          searchPlaceholder={f({ id: 'form.search' })}
                          selectButton={f({ id: 'form.groupChipSelectUnique.button.apply' })}
                          resetButton={f({ id: 'form.groupChipSelectUnique.button.reset' })}
                          options={vehicleOptions}
                          required={true}
                          searchTooltip={f({
                            id: 'alarms.section.vehicles.driverId.search.tooltip',
                          })}
                          selectButtonTooltip={f({
                            id: 'alarms.section.vehicles.driverId.selectButton.tooltip',
                          })}
                          resetButtonTooltip={f({
                            id: 'alarms.section.vehicles.driverId.resetButton.tooltip',
                          })}
                          bossCheckbox={f({ id: 'reports.chooseAllDrivers' })}
                          error={errors.driverIds?.message}
                          touched={true}
                          value={watch('driverIds')}
                          setValue={(value: string[]) => {
                            setValue('driverIds', value);
                            trigger('driverIds');
                            trackEvent(['reports', 'change', 'Report R06 driverIds changed']);
                          }}
                        />
                      )}
                    />
                  )}
                </Section>
              </div>

              <div className="col-xxl-6" data-cy="reports-col">
                <CmdBox id="reports-sending-settings" title={f({ id: 'reports.reportSettings' })}>
                  {reportSettingsOptionsR06.length > 0 ? (
                    [
                      <CmdCheckbox
                        id="reportSelectAll"
                        name="reportSelectAll"
                        label={f({ id: 'alarms.modal.timeFrame.button.selectAll' })}
                        onChangeCallback={handleReportParameter}
                        value={bossCheckboxValue}
                      />,
                      <Controller
                        name="sections"
                        control={control}
                        rules={{
                          required: errors.sections ? errors.sections.message : undefined,
                        }}
                        render={({ field }) => (
                          <CmdCheckboxGroup
                            {...field}
                            key="sections"
                            id="sections"
                            name="sections"
                            value={watch('sections')}
                            label={f({ id: 'enum.onlineMap.widget.types.ecoDrive' })}
                            options={getReportSettingsOptionsEcoDrive()}
                            gridTemplateColumns=" 1fr 1fr 1fr "
                            // bossCheckbox={f({ id: 'reports.chooseAll' })}
                            required={true}
                            setValue={(value: string[]) => {
                              setValue('sections', value);
                              trigger('sections');
                              trackEvent(['reports', 'change', 'Report R06 sections changed']);
                            }}
                            error={
                              errors.sections?.message
                                ? f({ id: 'reports.input.sections.error' })
                                : ''
                            }
                            touched
                            supportMessage={f({ id: 'reports.input.sections.error' })}
                          />
                        )}
                      />,
                      <Controller
                        name="violations"
                        control={control}
                        rules={{
                          required: errors.violations ? errors.violations.message : undefined,
                        }}
                        render={({ field }) => (
                          <CmdCheckboxGroup
                            {...field}
                            key="violations"
                            id="violations"
                            name="violations"
                            value={watch('violations')}
                            label={f({ id: 'reports.parametersSetting' })}
                            options={getReportSettingsOptions()}
                            gridTemplateColumns=" 1fr 1fr 1fr "
                            // bossCheckbox={f({ id: 'reports.chooseAll' })}
                            required={false}
                            setValue={(value: string[]) => {
                              setValue('violations', value);
                              trigger('violations');
                              trackEvent(['reports', 'change', 'Report R06 violations changed']);
                            }}
                          />
                        )}
                      />,
                    ]
                  ) : (
                    <CmdLabel
                      title={f({ id: 'reports.parametersSetting' })}
                      value={f({ id: 'reports.noParametersAvailable' })}
                      isLabelInTwoRows={true}
                    />
                  )}
                </CmdBox>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
